import {connect} from "react-redux";
import constants from "../../../constants";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import {EditorCard} from "./EditorCard";
import React from "react";
import OrgView from '../org/Org';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Icon} from "semantic-ui-react";
import MetaActionsBar from "../MetaActionsBar";

class EditorView extends React.Component {

    componentDidMount() {
        this.props.editorLoaded(this.props.orgId)
    }

    componentDidUpdate(prevProps) {
        if (this.props.task && prevProps.task.id !== this.props.task.id) {
            this.props.editorLoaded(this.props.orgId)
        }
        if (prevProps.orgId !== this.props.orgId) {
            this.props.editorLoaded(this.props.orgId)
        }
    }

    render() {
        const {
            loading,
            editor,
            promoteAttribute,
            demoteAttribute,
            promoteAddress,
            addNewAddress,
            addNewAttribute,
            removeAttribute,
            editAttribute,
            createAttribute,
            demoteAddress,
            addNewStock,
            promoteStock,
            demoteStock,
            deleteRepeatedAttribute,
            clearEditor,
            unmergeOrg,
            toggleOrgActiveState,
            showWarning,
            divorceParent,
        } = this.props;

        if (loading || !editor.org) {
            return null;
        }

        const handleEditClick = (attrs, display) => {
            if (!editor.org.active) {
                showWarning('Editing attributes is not allowed for disabled Orgs.');
            } else {
                editAttribute(attrs, display);
            }
        };

        const message = !!editor.org && !editor.org.active ? 'This org has been marked as disabled and will not be imported to Sabrage.' : '';

        return (
            <>
                <Container maxWidth={false}>
                    {!!editor.org &&
                    <MetaActionsBar
                        org={editor.org}
                        unmergeHandler={unmergeOrg}
                        toggleOrgActiveState={toggleOrgActiveState}
                        message={message}
                        divorceParent={divorceParent}
                    />
                    }
                </Container>
                <Grid container spacing={1} justify="flex-start">
                    <Grid item>
                        <OrgView
                            org={editor.org}
                            editAttribute={handleEditClick}
                            createAttribute={createAttribute}
                        />
                    </Grid>
                </Grid>
                <Dialog open={!!editor.visible} fullScreen={false} onClose={clearEditor} maxWidth='xl'>
                    <DialogTitle>
                        {editor.header}
                        <a>
                            <Icon name="window close" style={{float: "right"}} onClick={clearEditor}/>
                        </a>
                    </DialogTitle>
                    <DialogContent style={{minWidth: '600px'}}>
                        <EditorCard
                            {...editor}
                            promoteAttribute={promoteAttribute}
                            demoteAttribute={demoteAttribute}
                            promoteAddress={promoteAddress}
                            addNewAttribute={addNewAttribute}
                            addNewAddress={addNewAddress}
                            removeAttribute={removeAttribute}
                            demoteAddress={demoteAddress}
                            clearEditor={clearEditor}
                            addNewStock={addNewStock}
                            promoteStock={promoteStock}
                            demoteStock={demoteStock}
                            deleteRepeatedAttribute={deleteRepeatedAttribute}
                        />
                    </DialogContent>
                </Dialog>
            </>);
    }
}

const mapStateToProps = (state, props) => {
    return {
        editor: state.editor,
        orgId: props.org ? props.org.id : props.match.params.id,
        loading: state.editor.loading,
    }
};

const dispatchToProps = (dispatch, props) => {
    return {
        editorLoaded: (orgId) => dispatch(constants.actions.EDITOR_LOADED.getObj({
            orgId
        })),
        editAttribute: (attribute, options = {}) => {
            dispatch(constants.actions.EDIT_ATTRIBUTE.getObj({
                attribute,
                options
            }))
        },
        promoteAttribute: (attribute, org) => dispatch(constants.actions.PROMOTE_ATTRIBUTE.getObj({
            attribute,
            orgId: org.id
        })),
        demoteAttribute: (attribute, org) => dispatch(constants.actions.DEMOTE_ATTRIBUTE.getObj({
            attribute,
            orgId: org.id
        })),
        promoteAddress: (address, org) => dispatch(constants.actions.PROMOTE_ADDRESS.getObj({
            address,
            orgId: org.id
        })),
        demoteAddress: (address, org) => dispatch(constants.actions.DEMOTE_ADDRESS.getObj({
            address,
            orgId: org.id
        })),
        addNewAttribute: (attribute) => dispatch(constants.actions.CREATE_ATTRIBUTE.getObj({
            attribute
        })),
        addNewStock: (stock) => dispatch(constants.actions.CREATE_STOCK.getObj({
            stock
        })),
        promoteStock: (stock, org) => dispatch(constants.actions.PROMOTE_STOCK.getObj({
            attribute: stock,
            orgId: org.id
        })),
        demoteStock: (stock, org) => dispatch(constants.actions.DEMOTE_STOCK.getObj({
            attribute: stock,
            orgId: org.id
        })),
        addNewAddress: (address) => dispatch(constants.actions.SAVE_ADDRESS.getObj({
            address
        })),
        removeAttribute: (attribute, org) => dispatch(constants.actions.REMOVE_ATTRIBUTE.getObj({
            attribute,
            org
        })),
        clearEditor: () => dispatch(constants.actions.CLEAR_EDITOR.getObj()),
        deleteRepeatedAttribute: (attribute, org) => dispatch(constants.actions.DELETE_REPEATED_ATTRIBUTE.getObj({
            attribute,
            org
        })),
        unmergeOrg: (org) => dispatch(constants.actions.UNMERGE_ORG.getObj({
            org
        })),
        toggleOrgActiveState: (org) => {
            if (org.active) {
                dispatch(constants.actions.DEACTIVATE_ORG.getObj({
                    org
                }));
            } else {
                dispatch(constants.actions.ACTIVATE_ORG.getObj({
                    org
                }));
            }
        },
        showWarning: (message) => dispatch(constants.actions.SHOW_SNACKBAR.getObj({
            variant: 'warning',
            message
        })),
        divorceParent: (org) => dispatch(constants.actions.ORG_DIVORCE_PARENT.getObj({org})),
    }
};

export default connect(mapStateToProps, dispatchToProps)(EditorView);
