import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import AttrList from "../AttrList";
import AddButton from "../controls/AddButton";
import _ from "lodash";
import useCurrentlyEditing from "../hooks/useCurrentlyEditing";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

export default function DateAndTimePickers(props) {
    const {
        attribute,
        attributeName,
        promoteAttribute,
        addNewAttribute,
        removeAttribute,
        demoteAttribute,
        org
    } = props;

    const classes = useStyles();
    const [currentlyEditing, setCurrentlyEditing, onChange] = useCurrentlyEditing(org, attribute);
    const addButtonText = global.USER.sourceId !== currentlyEditing.source_id ? 'Add' : 'Update';
    const handleNewAttribute = () => {
        if (!_.isEmpty(currentlyEditing.values)) {            
            addNewAttribute({
                ...currentlyEditing,
                values: moment(currentlyEditing.values).format('YYYY-MM-DD')
            });
        }
    };

    return (
        <>
            <form className={classes.container} noValidate>
                <TextField
                    id="datetime-local"
                    label="Change Date"
                    type="date"
                    value={currentlyEditing.values}
                    className={classes.textField}
                    onChange={onChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <AddButton handleNewAttribute={handleNewAttribute} text={addButtonText} />
            </form>
            <AttrList
                type={attributeName}
                attributes={attribute}
                promoteAttribute={promoteAttribute}
                addNewAttribute={addNewAttribute}
                removeAttribute={removeAttribute}
                demoteAttribute={demoteAttribute}
                setCurrentlyEditing={setCurrentlyEditing}
                org={org}
            />
        </>
    );
}
