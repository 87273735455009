import _ from 'lodash'
import constants from '../constants';
import isReducerType from "./isReducerType";

const groups = (state = { list: [], current: global.USER.group }, action) => {
    const isType = isReducerType(action);

    if(isType(constants.actions.FETCH_GROUPS_SUCCESS)){
        return {
            ...state,
            list: action.groups
        };
    }

    if(isType(constants.actions.CURRENT_GROUP_SELECTED)){
        return {
            ...state,
            current: action.group
        };
    }

    return state;
};

export default groups;
