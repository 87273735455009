import _ from 'lodash'
import constants from '../constants';
import isReducerType from "./isReducerType";

const authorization = (state = {authorized: false}, action) => {
    const isType = isReducerType(action);
    if(isType(constants.actions.UNAUTHORIZED)){
        return {
            authorized: false,
            reason: action.error
        };
    }

    if(isType(constants.actions.AUTHORIZATION_SUCCESS)){
        return {
            authorized: true,
            roles: action.roles,
            group: action.group,
        }
    }
    return state;
};

export default authorization;
