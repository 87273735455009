import {Api} from "../services/api";
import Attribute from '../model/Attribute';
import Group from '../model/Group';
import {attributeTypes} from '../model/attributeTypes';
import Organization from "../model/Organization";
import _ from 'lodash';

const ORGS_ENDPOINT = '/api/v1/org';
const API_ENDPOINT = '/api/v1';

const handleOrgError = (error) => {
  if(error === "Unexpected end of JSON input"){
    return [];
  }

  throw error;
};

async function getAllAttributes(orgId) {
  const { error, data } = await Api.get(`${ORGS_ENDPOINT}/${orgId}/attributes/all`);
  if (error) return handleOrgError(error);
  return !!data ? data.map(a => new Attribute(a, a.name, attributeTypes[''], orgId)) : [];
}

async function getChildren(orgId) {
  const { data, error } = await Api.get(`${ORGS_ENDPOINT}/${orgId}/children`);
  if (error) return handleOrgError(error);
  return data || [];
}

async function getParent(orgId) {
  const { data, error } = await Api.get(`${ORGS_ENDPOINT}/${orgId}/parent`);
  if (error) return handleOrgError(error);
  return data || [];
}

async function getOrg(orgId) {
  const { error, data } = await Api.get( `${ORGS_ENDPOINT}/${orgId}`);
  if (error) return handleOrgError(error);
  return data;
}

async function getAllOrgData(orgId, opts, relationToParent) {
  opts = _.defaults(opts, {currentCount: 0, limit: 10});
  opts.currentCount = opts.currentCount + 1;

  const [org, attributes, rawChildren, rawParent] = await Promise.all([
    getOrg(orgId),
    getAllAttributes(orgId),
    getChildren(orgId),
    getParent(orgId),
  ]);

  let [parentList, children, merges] = [];

  if(opts.currentCount <= opts.limit) {
    children = await Promise.all(
          rawChildren.map(c => getAllOrgData(c.child_id, opts, c.description))
    );
    let mergeAttributes = _.filter(attributes, a => a.name === 'merged_from');
    merges = await Promise.all(
        mergeAttributes.map(m => getAllOrgData(m.values, opts, 'same'))
    );
    parentList = await Promise.all(rawParent.map(p => getAllOrgData(p.parent_id, { currentCount: 1, limit: 1}, relationToParent)));
  }
  const parent = _.isEmpty(parentList) ? {} : parentList[0];
  return new Organization({ id: orgId, domain: org.domain, attributes, children, parent: parent, relationToParent, active: org.active, merges});
}

function mergeWithParent({child, parent}) {
  return Api.get(`/api/v1/org/${parent.id}/merge/${child.id}?source_id=${global.USER.sourceId}`);
}

function makeChild({child, parent, description}) {
  description = description || 'subsidiary';
  return Api.put(`/api/v1/org/${child.id}/parent/${parent.id}`, {description})
}

const actionMap = {
  self: mergeWithParent,
  subsidiary: makeChild,
  campus: makeChild,
  division: makeChild,
  'business unit': makeChild,
  department: makeChild,
  acquired: makeChild,
  none: () => { /* noop */
  }
};

function revertRelationChanges(orgs) {
  _.reject(orgs, 'selectedParent').map((org) => {
    if (org.relation === "self") {
      Api.get(`/api/v1/org/${org.id}/revert`);
    }
    if (org.relation !== "self" && org.relation !== "ignore") {
      makeChild({parent: org, child: org, description: "---"})
    }
  })
}

function unmerge(org) {
  return Api.get(`${ORGS_ENDPOINT}/${org.id}/revert`);
}

function activateOrg(org) {
  return Api.get(`${ORGS_ENDPOINT}/${org.id}/activate`);
}

function deactivateOrg(org) {
  return Api.get(`${ORGS_ENDPOINT}/${org.id}/deactivate`);
}

function deleteOrg(org) {
  return Api.delete(`${ORGS_ENDPOINT}/${org.id}`);
}

function persistRelationChanges(orgs) {
  const parent = _.find(orgs, 'selectedParent');
  let errors = [];

  _.reject(orgs, 'selectedParent').map((org) => {
    const {data, error} = actionMap[org.relation]({child: org, parent, description: org.relation});
    if (error) errors.push[error];
  });

  return errors;
}

async function getGroups() {
  const {data, error} = await Api.get(`${API_ENDPOINT}/groups`);
  if (error) return handleOrgError(error);
  return data.map(g => new Group(g));
}

async function getGroupMembers(groupId) {
  const {data, error} = await Api.get(`${API_ENDPOINT}/groups/${groupId}`);
  if (error) return handleOrgError(error);
  return data;
}

export {
    persistRelationChanges,
    getAllAttributes,
    getAllOrgData,
    unmerge,
    revertRelationChanges,
    activateOrg,
    deactivateOrg,
    getGroups,
    getGroupMembers,
    deleteOrg,
    makeChild,
};
