import _ from "lodash";
import { Popup } from "semantic-ui-react";
import React from "react";
import HoverPopover from '../../../common/HoverPopover'
import Typography from '@material-ui/core/Typography'
import { Icon } from "semantic-ui-react";

const StockInfoDisplay = ({ attr }) => {
    const sources = global.ORG_SOURCES;
    const createdAt = new Date(attr.created_at);
    const sourceName = sources[attr.source_id] ? sources[attr.source_id] : "You";
    const attrValues = attr["values"];
    return (
        <>            
            <span className="stock_info">Symbol: {attrValues.symbol}</span>
            <br/>
            <span className="stock_info">Exchange: {attrValues.exchange}</span>
            <div>
                <br />
                <b>Source:</b> {sourceName.titleize()} <br />
                <b>Created:&nbsp;</b>{createdAt.toDateString()}
            </div>
            {attr.confidence === 0 && (
                <span>
                    <Typography>
                        <i>This attribute has been demoted and is considered to be inaccurate.</i>
                    </Typography>
                </span>
            )}
        </>
    );
}

export default StockInfoDisplay;