import React, {useEffect, useState} from 'react';
import _ from "lodash"
import AttrList from "../AttrList";
import {Input, Label} from "semantic-ui-react";
import AddButton from "../controls/AddButton";
import useCurrentlyEditing from "../hooks/useCurrentlyEditing";
import useUrlValidation from "./hooks/useUrlValidation";

const UrlEditor = ({attribute, promoteAttribute, addNewAttribute, demoteAttribute, removeAttribute, attributeName, org}) => {

  const [currentlyEditing, setCurrentlyEditing, onChange] = useCurrentlyEditing(org, attribute);
  const [url, setUrl, isValid, ValidationAlert] = useUrlValidation(currentlyEditing.values);

  const handleNewAttribute = () => {
    if (isValid) {
      if (!_.isEmpty(currentlyEditing.values)) {
        addNewAttribute(currentlyEditing);
      }
    }
  };

    const addButtonText = global.USER.sourceId !== currentlyEditing.source_id ? 'Add' : 'Update';

    return (
    <>
      <ValidationAlert />
      <Input
        placeholder={`Add new ${attribute.display()}`}
        style={{width: '80%'}}
        value={currentlyEditing.values}
        onChange={(e) => {
          onChange(e);
          setUrl(e.target.value);
        }}
      />
      <AddButton handleNewAttribute={handleNewAttribute} text={addButtonText} />
      {attribute &&
      <AttrList
        type={attributeName}
        attributes={attribute}
        promoteAttribute={promoteAttribute}
        addNewAttribute={addNewAttribute}
        removeAttribute={removeAttribute}
        demoteAttribute={demoteAttribute}
        setCurrentlyEditing={setCurrentlyEditing}
        org={org}
      />
      }
    </>
  );
};

export default UrlEditor;
