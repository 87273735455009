import Typography from "@material-ui/core/Typography";
import React from "react";

const AttributeMetaItems = ({attr}) => {
    const sources = global.ORG_SOURCES;
    const createdAt = new Date(attr.created_at);
    const updatedAt = new Date(attr.updated_at);
    const sourceName = sources[attr.source_id] ? sources[attr.source_id] : "You";
    return (
        <>
            <div>
                <br/>
                <b>Source:</b> {sourceName.titleize()} <br/>
                <b>Created:&nbsp;</b>{createdAt.toDateString()} <br/>
                <b>Updated:&nbsp;</b>{updatedAt.toDateString()} <br/>
                <b>Confidence:&nbsp;</b>{attr.confidence}
            </div>
            {attr.confidence === 0 && (
                    <span>
                        <Typography>
                            <i>This attribute has been demoted and is considered to be inaccurate.</i>
                        </Typography>
                    </span>
                )
            }
        </>
    );
};

export default AttributeMetaItems;
