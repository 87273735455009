import moment from 'moment';

const dataTypes = {
  STRING: 'string',
  ENUM: 'enumerable',
  MULTI: 'multi',
  REPEATED: 'repeated',
  BLOB: 'blob',
  ADDRESS: 'address',
  STOCK: 'stock',
  URL: 'url',
  PRIVATE: '',
  IMAGE: 'image',
  DATE: 'date',
};

const attributeTypes = {
  curation_status: {
    dataType: dataTypes.ENUM,
    repeated: false,
    options: [
        'Unverified',
        'Needs Attention',
        'Screened',
        'Verified'
    ]
  },
  address: {
    dataType: dataTypes.ADDRESS,
    repeated: false
  },
  org_classification: {
    dataType: dataTypes.MULTI,
    repeated: true
  },
  org_synonym: {
    dataType: dataTypes.STRING,
    repeated: true
  },
  org_name: {
    dataType: dataTypes.STRING,
    repeated: false
  },
  org_status: {
    dataType: dataTypes.ENUM,
    options: ['active', 'closed'],
    repeated: false
  },
  org_linkedin_url: {
    dataType: dataTypes.URL,
    repeated: false
  },
  org_employee_count: {
    dataType: dataTypes.ENUM,
    repeated: false,
    options: [
      '1-10',
      '11-50',
      '51-100',
      '101-250',
      '251-500',
      '501-1000',
      '1001-5000',
      '5001-10000',
      '10000+',
      'Unknown'
    ]
  },
  org_domain: {
    dataType: dataTypes.URL,
    repeated: false
  },
  org_description: {
    dataType: dataTypes.BLOB,
    repeated: false
  },
  org_about: {
    dataType: dataTypes.BLOB,
    repeated: false
  },
  stock: {
    dataType: dataTypes.STOCK,
    repeated: true
  },
  revert: {
    dataType: dataTypes.PRIVATE,
    repeated: false
  },
  org_logo_url: {
    dataType: dataTypes.IMAGE,
    repeated: false
  },
  founded_on: {
    dataType: dataTypes.DATE,
    formatter: date => date && date.top ? moment(date.top.values).format('L') : date ? moment(date.values).format('L') : '---',
    repeated: false
  }
};

export { dataTypes, attributeTypes};
