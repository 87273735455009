import {all, call, put, select, takeEvery} from 'redux-saga/effects'
import constants from '../constants'
import {Api} from '../services/api'
import putAction from "./putAction";

const getUser = state => state.user;
const getAttribute = state => state.editor.attribute;

export default function* () {
    yield all([
        takeEvery(constants.actions.PROMOTE_ATTRIBUTE.type, promoteAttribute),
        takeEvery(constants.actions.DEMOTE_ATTRIBUTE.type, demoteAttribute),
        takeEvery(constants.actions.PROMOTE_ADDRESS.type, promoteAddress),
        takeEvery(constants.actions.PROMOTE_STOCK.type, promoteAttribute),
        takeEvery(constants.actions.DEMOTE_ADDRESS.type, demoteAddress),
        takeEvery(constants.actions.CREATE_ATTRIBUTE.type, createOrUpdateAttribute),
        takeEvery(constants.actions.REMOVE_ATTRIBUTE.type, deleteAttribute),
        takeEvery(constants.actions.SAVE_ADDRESS.type, createOrUpdateAddress),
        takeEvery(constants.actions.SET_ORG.type, loadAttribute),
        takeEvery(constants.actions.CREATE_STOCK.type, createStock),
        takeEvery(constants.actions.DEMOTE_STOCK.type, demoteAttribute),
        takeEvery(constants.actions.DELETE_REPEATED_ATTRIBUTE.type, deleteRepeatedAttribute),
    ])
}

function* transportError(action, error) {
    yield put(constants.actions.SHOW_SNACKBAR.getObj({
        variant: 'error',
        message: `Error performing ${action.type}: ${error}`
    }));
};

function* promoteAttribute(action) {
    const {data, error} = yield call(
        Api.post,
        `/api/v1/attribute/${action.attribute.id}/confidence/top`
    );
    yield putAction(constants.actions.FETCH_ORG_ATTRIBUTES,{orgId: action.orgId});
}

function* demoteAttribute(action) {
    const orgId = _.isEmpty(action.org) ? action.orgId : action.org.id;
    const {data, error} = yield call(
        Api.post,
        `/api/v1/attribute/${action.attribute.id}/confidence/bottom`
    );
    yield putAction(constants.actions.FETCH_ORG_ATTRIBUTES,{orgId});
}

function* promoteAddress(action) {
    const {data, error} = yield call(
        Api.post,
        `/api/v1/address/${action.address.id}/top`
    );

    if (error) return yield transportError(action, error);

    yield putAction(constants.actions.FETCH_ORG_ATTRIBUTES, {orgId: action.orgId});
}

function* demoteAddress(action) {
    const {data, error} = yield call(
        Api.post,
        `/api/v1/address/${action.address.id}/bottom`
    );
    if (error) return yield transportError(action, error);

    yield putAction(constants.actions.FETCH_ORG_ATTRIBUTES, {orgId: action.address.orgId});
}

function* modifyAttribute(action, endpoint, attribute) {
    const {data, error} = yield call(
        Api.put,
        endpoint,
        attribute
    );

    if (error) return yield transportError(action, error);

    yield putAction(constants.actions.FETCH_ORG_ATTRIBUTES, { orgId: action.attribute.orgId });
}

function* createOrUpdateAttribute(action) {
    const user = yield select(getUser);
    if (action.attribute.source_id === user.sourceId) {
        return yield call(updateAttribute, action, user);
    }
    return yield call(createAttribute, action, user);
}

function* updateAttribute(action, user) {
    yield call(
        modifyAttribute,
        action,
        `/api/v1/attribute/${action.attribute.id}/`,
        {
            ...action.attribute.toRequest(),
            source_id: user.sourceId,
        }
    );
}

function* createAttribute(action, user) {
    yield call(
        modifyAttribute,
        action,
        `/api/v1/org/${action.attribute.orgId}/attribute/`,
        {
            ...action.attribute.toRequest(),
            source_id: user.sourceId,
        }
    )
}

function* deleteAttribute(action) {
    const {data, error} = yield call(
        Api.delete,
        `/api/v1/attribute/${action.attribute.id}`
    );

    if (error && error !== 'Unexpected end of JSON input') return yield transportError(action, error);

    yield putAction(constants.actions.FETCH_ORG_ATTRIBUTES, {orgId: action.org.id});
}

const CREATE_ADDRESS_PATH = (orgId, userId) => `/api/v1/org/${orgId}/address/${userId}`;
const UPDATE_ADDRESS_PATH = (addressId, userId) => `/api/v1/address/${addressId}/${userId}`;

const shouldUpdateRepeatedAttribute = (attribute, user) => (attribute.id > 0 && attribute.source_id === user.sourceId);
const shouldDemoteOldAttribute = (attribute, user) => (attribute.id > 0 && attribute.source_id !== user.sourceId);

function* deleteRepeatedAttribute(action) {
    const user = yield select(getUser);
    const {attribute, org} = action;

    if (shouldDemoteOldAttribute(attribute, user)) {
        yield putAction(constants.actions.DEMOTE_ATTRIBUTE, {
            attribute,
            org,
            confidence: 0
        });
    } else {
        yield putAction(constants.actions.REMOVE_ATTRIBUTE,{
            attribute,
            org
        })
    }
}

function updateAddress(address, user) {
    return Api.put(UPDATE_ADDRESS_PATH(address.id, user.sourceId), {...address.values, source_id: user.sourceId});
};

function createAddress(address, user) {
    return Api.put(CREATE_ADDRESS_PATH(address.orgId, user.sourceId), {...address.values, source_id: user.sourceId});
};

function* createOrUpdateAddress(action) {
    const user = yield select(getUser);
    const address = action.address;
    const apiCall = shouldUpdateRepeatedAttribute(address, user) ? updateAddress : createAddress;
    const {data, error} = yield call(
        apiCall,
        address,
        user
    );

    if (error && error !== 'Unexpected end of JSON input') return yield transportError(action, error);

    if (shouldDemoteOldAttribute(address, user)) {
        return yield putAction(constants.actions.DEMOTE_ADDRESS,{address});
    }

    yield putAction(constants.actions.FETCH_ORG_ATTRIBUTES, { orgId: action.address.orgId });
}

function* loadAttribute(action) {
    const currentAttribute = yield select(getAttribute);
    if (!currentAttribute) return;
    yield putAction(constants.actions.UPDATE_EDITOR_ATTRIBUTE, { attribute: action.org.attributes[currentAttribute.apiKey]});
}

function* createStock(action) {
    const user = yield select(getUser);

    const newAttr = {
        exchange: action.stock.exchange,
        symbol: action.stock.symbol
    };

    const {data, error} = yield call(
        Api.put,
        `/api/v1/org/${action.stock.orgId}/stock/${user.sourceId}`,
        newAttr
    );

    if (error) return yield transportError(action, error);

    yield putAction(constants.actions.FETCH_ORG_ATTRIBUTES, {orgId: action.stock.orgId});
}
