import React from "react"
import { Loader, Dimmer } from 'semantic-ui-react'

const Spinner = ({open}) => {
    return(
        <Dimmer active={open} page>
            <Loader active={open}>Loading content...</Loader>                
        </Dimmer>
    );
}
export default Spinner;