import { connect } from "react-redux";
import constants from "../../../constants";
import WorkList from "./WorkList";


const mapStateToProps = (state, props) => {
    return {
        org: state.org,
        tasks: state.curationList,
        activeIndex: state.curationList.activeTabIndex,
        currentGroup: state.groups.current || global.USER.group,
        activePages: state.curationList.workListPage,
    }
};

const dispatchToProps = dispatch => (
    {
        fetchTasks: (status, group) => dispatch(constants.actions.FETCH_TASKS.getObj({
            status,
            group
        })),
        fetchOrg: (orgId) => dispatch(constants.actions.FETCH_ORG_BEGIN.getObj({
            orgId
        })),
        setActivePage: (page, listType) => dispatch(constants.actions.SET_ACTIVE_PAGE.getObj({
            page,
            listType
        })),
        setActiveTab: (index) => dispatch(constants.actions.SET_ACTIVE_TAB.getObj({
            index
        })),
        setCurrentGroup: (group) => dispatch(constants.actions.CURRENT_GROUP_SELECTED.getObj({
            group
        })),
        assignTask: o => dispatch(constants.actions.ASSIGN_TASK.getObj(o)),
        assignTaskBulk: o => dispatch(constants.actions.ASSIGN_TASK_BULK.getObj(o))
    }
);

export default connect(mapStateToProps, dispatchToProps)(WorkList);
