import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import createAuth0Client from '@auth0/auth0-spa-js';
import * as Cookies from 'es-cookie'
import moment from 'moment'

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  cookieToken,
  ...initOptions
}) => {

  const [authData, setAuthData] = useState({
    ...cookieToken,
    authenticated: !_.isEmpty(cookieToken.user),
  });

  useEffect(() => {
    const init = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      if (isAuthenticated) {
        const token = await auth0FromHook.getTokenSilently();
        const user = await auth0FromHook.getIdTokenClaims();
        const expire = moment.unix(user.exp);

        Cookies.set('api', JSON.stringify({ token, user }), { expires: expire.toDate() });
        setAuthData({
          user,
          token: token,
          authenticated: true
        })
      } else {
        await auth0FromHook.loginWithRedirect();
      }

    };

    if (!authData.authenticated) {
      init();
    }

  }, []);

  const logout = async (p) => {
    Cookies.remove('api');
    const auth0FromHook = await createAuth0Client(initOptions);
    auth0FromHook.logout({
      ...p,
      returnTo: window.location.origin
    });
  };

  return (
    <Auth0Context.Provider
      value={{
        loginWithRedirect: (...p) => loginWithRedirect(...p),
        logout: (...p) => logout(...p),
        user: {
          ...authData.user,
          token: authData.token,
        },
        token: authData.token,
        isAuthenticated: authData.authenticated,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
