import _ from 'lodash';
import { makeStyles, Typography } from '@material-ui/core'
import DialogTitle from '@material-ui/core/es/DialogTitle/DialogTitle'
import DialogContent from '@material-ui/core/es/DialogContent/DialogContent'
import Dialog from '@material-ui/core/Dialog/Dialog'
import React from 'react'
import Card from '@material-ui/core/es/Card/Card'
import CircularProgress from '@material-ui/core/es/CircularProgress/CircularProgress'
import DialogActions from '@material-ui/core/es/DialogActions/DialogActions'
import Button from '@material-ui/core/es/Button/Button'

const useStyles = makeStyles({
  card: {
    minWidth: '250px',
    maxWidth: '250px',
    float: 'left',
    marginRight: '8px',
    overflow: 'hidden',
    height: 150,
    maxHeight: 150,
    backgroundColor: 'white',
    padding: '5px',
  },
});

const HighlightDifference = ({oldValue, children}) => {
  if (_.toLower(_.trim(children)) === _.toLower(_.trim(oldValue))) {
    return <span>{children}</span>;
  }
  return <span style={{backgroundColor: 'LightGreen'}}>{children}</span>;
}

const AddressValidator = ({original, suggestion, open, handleClose, loading, handleSelectAddress, error}) => {
  const classes = useStyles();

  const isTheSame = _.isEqual(original, suggestion);
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="address-validator-title">
      <DialogTitle id="address-validator-title">Address Validator</DialogTitle>
      <DialogContent style={{backgroundColor: 'lightgray'}}>
        <Card className={classes.card}>
          <Typography variant="h6">
            Original
          </Typography>
          <Typography component="div">
            {original.addr_street_address}
            <br />
            {original.addr_city}, {original.addr_state} {original.addr_postal_code}
          </Typography>
        </Card>
        <Card className={classes.card}>
          {!loading && !error && <>
            <Typography variant="h6">
              Validator Suggests
            </Typography>
            <Typography component="div">
              <HighlightDifference oldValue={original.addr_street_address}>
                {suggestion.addr_street_address}
              </HighlightDifference>
              <br />
              <HighlightDifference oldValue={original.addr_city}>{suggestion.addr_city}</HighlightDifference>,&nbsp;
              <HighlightDifference oldValue={original.addr_state}>{suggestion.addr_state}</HighlightDifference>&nbsp;
              <HighlightDifference oldValue={original.addr_postal_code}>{suggestion.addr_postal_code}</HighlightDifference>
            </Typography>
            {isTheSame && <Typography variant="h6">No Difference Detected.</Typography>}
          </>}
          {loading && <CircularProgress />}
          {!loading && error && <p>There was an error accessing the address validator. Click Keep Original to continue.</p>}
        </Card>
      </DialogContent>
      {!isTheSame && <DialogActions>
        <Button onClick={() => handleSelectAddress('original')} color="primary">
          Keep Original
        </Button>
        {!error && <Button onClick={() => handleSelectAddress('suggestion')} color="primary">
          Use Suggestion
        </Button>}
      </DialogActions>}
      {isTheSame && <DialogActions>
        <Button onClick={() => handleSelectAddress('original')} color="primary">
          Continue
        </Button>
      </DialogActions>}
    </Dialog>
  );

};

export default AddressValidator;
