function Group ({source_id, name}) {
    this.name = name;
    this.id = source_id;

    this.toRequest = () => ({
        name: this.name,
        source_id: this.id
    });
}

export default Group;
