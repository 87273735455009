import React, {Component} from 'react';
import _ from 'lodash';
import NewAddressInputs from "./NewAddressInputs";
import AddressList from "./AddressList";
import {connect} from 'react-redux'
import Button from '@material-ui/core/Button'
import Attribute from '../../../../model/Attribute';
import constants from '../../../../constants'
import address from '../../../../reducers/address'
import AddressValidator from './AddressValidator'

class AddressEditor extends Component {

    componentWillUnmount() {
        this.props.cancelValidation();
    }

    shouldAddAddress = () => {
        return (
            !_.isEmpty(_.get(this.props, 'address.currentAddress.addr_country', '').trim())
        )
    };

    handleNewAttribute = () => {
        if (this.shouldAddAddress()) {
            const {currentAddress} = this.props.address;
            Object.keys(currentAddress).map((a) => _.trim(currentAddress[a]));
            const newAttr = new Attribute({
                id: currentAddress.id,
                source_id: currentAddress.source_id,
                values: currentAddress,
            }, 'address', currentAddress.type, this.props.org.id);
            this.props.addNewAddress(newAttr);
        }
    };

    handleEdit = (address) => {
        this.props.editAddress({
            ...address.values,
            id: address.id,
            source_id: address.source_id,
        })
    };

    handleValidateAddress = () => {
        this.props.validateAddress(this.props.address.currentAddress)
    };

    handleClose = () => {
        this.props.cancelValidation();
    }
    handleSelectAddress = (key) => {
        this.props.selectValidatedAddress(key);
    }

    render() {

        const {address} = this.props;

        const buttonStyles = {
            marginTop: '1em',
            marginBottom: '2em',
            marginLeft: '2em',
            float: 'right',
            backgroundColor: '#21ba45',
            boxShadow: 'none',
        };

        const open = !!address.original.addr_country;

        return (
            <>
                <br/>
                <div>
                    <NewAddressInputs handleSetState={this.props.handleChange} address={address.currentAddress}
                                      required={{addr_country: true}} user={this.props.user}/>
                </div>
                {this.shouldAddAddress() && (
                    <>
                        {address.validated &&
                        <Button variant="contained" color="primary" onClick={this.handleNewAttribute}
                                style={buttonStyles}>
                            Save
                        </Button>}
                        <Button variant="contained" color="primary" onClick={this.handleValidateAddress}
                                style={buttonStyles}>
                            Validate
                        </Button>
                    </>
                )}


                <br/>
                <AddressList {...this.props} onEdit={this.handleEdit}/>
                <AddressValidator
                    original={address.original}
                    suggestion={address.suggestion}
                    open={open}
                    handleClose={this.handleClose}
                    handleSelectAddress={this.handleSelectAddress}
                    loading={address.loading}
                    error={address.error}
                />
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    user: state.user,
    address: state.address
});

const dispatchToProps = (dispatch, ownProps) => ({
    validateAddress: (address) => dispatch(constants.actions.VALIDATE_ADDRESS_BEGIN.getObj({
        address
    })),
    cancelValidation: () => dispatch(constants.actions.VALIDATE_ADDRESS_CANCEL.getObj({})),
    handleChange: (name, value) => dispatch(constants.actions.ADDRESS_CHANGE.getObj({
            name,
            value
    })),
    editAddress: address => dispatch(constants.actions.ADDRESS_EDIT.getObj({
        address,
    })),
    selectValidatedAddress: key => dispatch(constants.actions.ADDRESS_SELECT_VALIDATED.getObj({
        key,
    }))
});

export default connect(mapStateToProps, dispatchToProps)(AddressEditor);
