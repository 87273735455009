import {all, call, put, select, takeEvery} from 'redux-saga/effects'
import constants from '../constants'
import {persistRelationChanges, revertRelationChanges, makeChild} from "../client/orgs";
import putAction from "./putAction";
import {Api} from "../services/api";

const getOrgs = state => state.corral.corralItems;

export default function* () {
    yield all([
        takeEvery(constants.actions.CORRAL_SUBMIT_CHANGES.type, persistChanges),
        takeEvery(constants.actions.CORRAL_REVERT_CHANGES.type, revertMerges),
        takeEvery(constants.actions.ORG_DIVORCE_PARENT.type, divorceParent),
    ])
}

function* divorceParent(action) {
    const {org} = action;
    yield call(makeChild, {child: org, parent: org, description: 'Top Level'});
    yield putAction(constants.actions.FETCH_ALL_ORG_DATA, { orgId: org.id });
}

function* revertMerges(action) {
    const orgs = yield select(getOrgs);
    if (orgs) yield call(revertRelationChanges, orgs);

    yield putAction(constants.actions.CORRAL_REVERT_SUCCESS);

    yield putAction(constants.actions.SHOW_SNACKBAR,{
        variant: 'success',
        message: 'Changes reverted!'
    });
}

function* persistChanges(action) {
    const orgs = yield select(getOrgs);
    let errors;

    if (orgs) {
        errors = yield call(persistRelationChanges, orgs);
    }

    if (errors.length > 0) {
        yield putAction(constants.actions.CORRAL_MERGE_FAIL);
        yield putAction(constants.actions.SHOW_SNACKBAR, {
            variant: 'error',
            message: 'Network Failure: could not persist changes!'
        });
        return
    }

    yield putAction(constants.actions.CORRAL_MERGE_SUCCESS);
    yield putAction(constants.actions.SHOW_SNACKBAR, {
        variant: 'success',
        message: 'Changes persisted!'
    })
}
