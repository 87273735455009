import constants from '../constants';
import Organization from '../model/Organization';
import isReducerType from "./isReducerType";

const search = (state = { results: undefined }, action) => {
    const isType = isReducerType(action);

    if (isType(constants.actions.CLEAR_SEARCH)) {
        return {}
    }

    if (isType(constants.actions.SEARCH_SUCCESS)) {
        const { results } = action;
        return {
            results : results.map((r) => new Organization(r)),
            loading: false
        }
    }

    if (isType(constants.actions.SEARCH_PENDING)) {
        return {
            results: undefined,
            loading: true
        }
    }

    return state;
};

export default search;
