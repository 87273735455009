import React, {useState} from 'react'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import makeStyles from "@material-ui/core/styles/makeStyles";
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export const EnumerableInput = ({attribute, onChange}) => {

    const options = _.get(attribute, 'type.options');
    const formattedOptions = options.map((item) => ({key: item, value: item, text: item}));
    const classes = useStyles();
    const [value, setValue] = useState(_.get(attribute, 'top.values', ""));

    const handleChange = (event) => {
        onChange(event);
        setValue(event.target.value);
    };

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id={attribute.apiKey}>{`${attribute.display()}`}</InputLabel>
            <Select onChange={handleChange} labelid={attribute.apiKey} value={value.toLowerCase()}>
                {formattedOptions.map(option => <MenuItem key={option.key} value={option.value}>{option.text}</MenuItem>)}
            </Select>
        </FormControl>
    );
};

export default EnumerableInput;
