import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {connect} from "react-redux"

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import ListItemText from '@material-ui/core/ListItemText';
import CorralButton from './CorralButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link} from 'react-router-dom'
import LinkIcon from '@material-ui/icons/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        right: 0,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    progress: {
        margin: theme.spacing(2),
        flexGrow: 1,
    },
}));

function ResultsList({results}) {
    const classes = useStyles();
    const incrementBy = 10;
    const [bounds, setBounds] = useState({start: 0, end: incrementBy});
    const next = () => setBounds({start: bounds.start + incrementBy, end: bounds.end + incrementBy});
    const prev = () => setBounds({start: bounds.start - incrementBy, end: bounds.end - incrementBy});

    return (
        <>
            <List className={classes.root} dense disablePadding>
                {results.slice(bounds.start, bounds.end).map(org => {
                    const id = org.id;
                    const labelId = `search-result-list-label-${id}`;
                    const attributes = org.attributes;
                    return (
                        <ListItem key={id} role={undefined} dense disableGutters>
                            <ListItemIcon>
                                <CorralButton item={org}/>
                            </ListItemIcon>
                            <ListItemIcon>
                                <Link to={`/org/edit/${id}`}>
                                    <LinkIcon/>
                                </Link>
                            </ListItemIcon>
                            <ListItemText id={labelId}>
                                <Typography>
                                    <b>{attributes.org_name.top.values}</b>
                                    &nbsp;<br/>
                                    {attributes.curation_status.top ? attributes.curation_status.top.values : 'Unverified'} | {attributes.address.top ? `${attributes.address.top.values.addr_city}, ${attributes.address.top.values.addr_country}` : '---'}
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    );
                })}
            </List>
            {bounds.start > 0 &&
            <a onClick={prev}>
                &nbsp;<NavigateBeforeIcon/>
            </a>
            }
            &nbsp;
            {bounds.end < results.length &&
            <a onClick={next}>
                &nbsp;<NavigateNextIcon/>
            </a>
            }
        </>
    );
}

const SearchResultsContainer = ({results, loading}) => {
    const classes = useStyles();

    if (!results && !loading) {
        return (<></>);
    }

    return (
        <div className={classes.root}>
            <ExpansionPanel defaultExpanded={true}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>Search Results</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {loading &&
                    <div>
                        <CircularProgress className={classes.progress}/>
                    </div>
                    }
                    {!loading && results.length > 0 &&
                    <ResultsList results={results}/>
                    }
                    {!loading && results.length === 0 &&
                    <div>
                        No results
                    </div>
                    }
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state.search,
    }
};

export default connect(mapStateToProps)(SearchResultsContainer);
