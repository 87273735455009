import React from "react";
import Typography from '@material-ui/core/Typography'
import {dataTypes} from "../../../model/attributeTypes";
import AttributeMetaItems from "./AttributeMetaItems";

const TruncatedLink = ({url}) => {
  const display = typeof url === 'string' && url.length > 45 ? `${url.substr(0, 41)}...` : url;
  const qualifiedUrl = url.startsWith('http') ? url : `http://${url}`;
  return (
    <a target="_new" href={qualifiedUrl}>{display}</a>
  )
};

const AttributeDisplay = ({attr}) => {
  console.log(attr);
  const displayValue = attr.type.formatter ? attr.type.formatter(attr) : attr.values;
  return (
    <>
      {attr.type.dataType === dataTypes.URL ? <TruncatedLink url={displayValue}/> : <Typography>{displayValue}</Typography>}
      <AttributeMetaItems attr={attr} />
    </>
  );
};

export default AttributeDisplay;
