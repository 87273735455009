import {all, call, put, takeLeading} from 'redux-saga/effects'
import constants from '../constants'
import {Api} from '../services/api'
import putAction from "./putAction";

export function* sourceSagas() {
    yield all([
        takeLeading(constants.actions.USER_NOT_FOUND_IN_SOURCES.type, createSourceForUser)
    ]);
}

function* createSourceForUser(action) {
    const {data, error} = yield call(
        Api.put,
        '/api/v1/source',
        {
            "name": action.user.email,
            "confidence": constants.curator.defaultConfidences.HUMAN
        }
    );

    if (data) {
        yield putAction(constants.actions.ADD_SOURCE_TO_USER,{id: data.id});

        global.USER = {
            user: action.user.email,
            sourceId: data.id
        };

        global.ORG_SOURCES[data.id] = action.user.email;

        yield putAction(constants.actions.USER_LOADED);
    } else {
        yield putAction(constants.actions.ADD_SOURCE_TO_USER_ERROR,{ error });
    }

}
