import constants from '../constants';
import isReducerType from "./isReducerType";

const DEFAULT = {
    loading: false,
    error: false,
    userList: [],
    groupList: [],
    orgs: {},
    workListPage: {
        group: undefined,
        to_do: 0,
        in_progress: 0,
        punted: 0,
        done: 0,
    },
    activeTabIndex: 0,
};

const curationList = (state = DEFAULT, action) => {
    const isType = isReducerType(action);

    if(isType(constants.actions.CURRENT_GROUP_SELECTED)){
        return {
            ...state,
            workListPage: {
                ...state.workListPage,
                group: action.group,
            }
        };
    }

    if (isType(constants.actions.SET_ACTIVE_TAB)) {
        return {
            ...state,
            activeTabIndex: action.index
        }
    }

    if (isType(constants.actions.SET_ACTIVE_PAGE)) {
        state.workListPage[action.listType] = action.page;
        return state;
    }

    if (isType(constants.actions.FETCH_TASKS_BEGIN)) {
        return {
            ...state,
            loading: true
        }
    }

    if (isType(constants.actions.FETCH_ORG_SUCCESS)) {
        return {
            ...state,
            orgs: {
                ...state.orgs,
                [action.orgId]: action.payload
            }
        };
    }

    if (isType(constants.actions.FETCH_TASKS_BEGIN)) {
        return {
            ...state,
            userList: [],
            groupList: [],
            error: false,
            loading: true
        }
    }

    if (isType(constants.actions.FETCH_TASKS_SUCCESS)) {
        return {
            ...state,
            loading: false,
            userList: action.payload.user,
            groupList: action.payload.group,
            error: false
        }
    }

    if (isType(constants.actions.FETCH_TASKS_ERROR)) {
        return {
            ...state,
            loading: false,
            userList: [],
            groupList: [],
            error: true,
        }
    }

    return state;
}

export default curationList;
