import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import CallSplitIcon from '@material-ui/icons/CallSplit';

import OrgRelationBreadCrumbs from "./OrgRelationBreadCrumbs";
import CorralButton from "./CorralButton";
import React from "react";
import DeleteButton from "./DeleteButton";
import {Typography} from "@material-ui/core";


const MetaActionsBar = ({org, unmergeHandler, toggleOrgActiveState, divorceParent}) => {
    const [state, setState] = React.useState({
        checked: org.active,
    });

    const handleChange = name => event => {
        toggleOrgActiveState(org);
        setState({...state, [name]: event.target.checked});
    };
    const activeLabel = state.checked ? 'Enabled' : 'Disabled';
    return (
        <Grid container direction='row' style={{paddingTop: '15px', paddingBottom: '15px'}} spacing={3} alignItems='center' justify="flex-start" size='large'>
            <Grid item xs>
                <OrgRelationBreadCrumbs org={org}/>
            </Grid>
            <Grid item xs>
                <Grid container direction='row' justify='flex-end' alignItems='center'>
                    <FormGroup row>
                        {!org.attributes.merged &&
                            <Grid item xl>
                                <Tooltip title="Mark Org As Not Usable" placement="bottom">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={state.checked}
                                                onChange={handleChange('checked')}
                                                value="checked"
                                                color='primary'
                                            />
                                        }
                                        label={activeLabel}
                                    />
                                </Tooltip>
                            </Grid>
                        }
                        {org.attributes.merged &&
                            <Grid item xl>
                                <Button
                                    color="primary"
                                    aria-label="large contained secondary button group"
                                    onClick={() => unmergeHandler(org)}
                                >
                                    <SettingsBackupRestoreIcon/>
                                    <Typography>
                                        Unmerge
                                    </Typography>
                                </Button>
                            </Grid>
                        }
                        {!org.attributes.merged && org.active &&
                            <Grid item xl>
                                <CorralButton
                                    item={org}
                                    addText="add to review"
                                    removeText="remove from review"
                                />
                            </Grid>
                        }
                        {!_.isEmpty(org.parent) &&
                            <Grid item xl>
                                <Button
                                    onClick={() => divorceParent(org)}
                                    color="primary"
                                >
                                    <CallSplitIcon/>
                                    <Typography>
                                        Remove Parent
                                    </Typography>
                                </Button>
                            </Grid>
                        }
                        <Grid item xl>
                            <DeleteButton org={org} />
                        </Grid>
                    </FormGroup>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default MetaActionsBar;
