import React, { useRef, useState } from 'react'
import Select from '@material-ui/core/Select';
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { Typography } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const selectStyle = {
    width: '25%',
    marginBottom: '5px',
    marginRight: '5px',
};

const Countries = ({value, onChange}) => {
    const countryNames = countries.getNames("en");
    const countryCodes = _.invert(countries.getAlpha3Codes());
    const threeLetterNameMap = Object.keys(countryCodes).map(key => ({ code: countryCodes[key], name: countryNames[key] }));
    const handleChange = (e, o) => {
        if(o){
            onChange(null, {value: o.code});
        }
    };
    return (
        <FormControl variant='filled' style={selectStyle}>
            <Autocomplete
                onChange={handleChange}
                options={threeLetterNameMap}
                getOptionLabel={option => option.name || "Country"}
                value={_.find(threeLetterNameMap, ['code', value]) || ""}
                renderInput={params => (
                    <TextField {...params} variant="outlined" fullWidth />
                )} />
        </FormControl>
    )
};


const AddressType = ({label, onChange, value}) => {

    const inputLabel = useRef();
    const changeHandler = (e,o) => {
        onChange(e);
    };
    return (
      <FormControl variant="filled" style={selectStyle}>
        <InputLabel ref={inputLabel} htmlFor="address_type">
            Address Type
        </InputLabel>
        <Select
          value={value}
          onChange={changeHandler}
          inputProps={{
              name: 'address_type',
              id: 'address_type',
          }}
        >
            <MenuItem value="">
                <em>Select Address Type</em>
            </MenuItem>
            <MenuItem value="Headquarters">Headquarters</MenuItem>
            <MenuItem value="Global Headquarters">Global Headquarters</MenuItem>
            <MenuItem value="Regional Headquarters">Regional Headquarters</MenuItem>
            <MenuItem value="R&D / Laboratory">R&D / Laboratory</MenuItem>
            <MenuItem value="Manufacturing Site">Manufacturing Site</MenuItem>
            <MenuItem value="Clinical">Clinical</MenuItem>
            <MenuItem value="Business Development">Business Development</MenuItem>
            <MenuItem value="Operating Site">Operating Site</MenuItem>

        </Select>
    </FormControl>);
};

export const NewAddressInputs = ({ handleSetState, address, required, user }) => {
    const inputStyle = {
        width: '100%',
        marginBottom: '5px',
    };

    const map = {
        description: {
            component: AddressType,
            additionalProps: ({label}) => ({
                text: label,
                options: [
                    {
                        text: 'HQ',
                        key: 'HQ',
                        value: 'HQ',
                    }
                ]
            })
        },
        addr_country: {
            component: Countries,
            additionalProps: () => {}
        },
        default: {
            component: TextField,
            additionalProps: () => {}
        }
    };

    return (
        <React.Fragment>
            {address.source_id > 0 && user.sourceId !== address.source_id && (
              <Typography variant={"caption"}>
                  You are not the source for this address, upon saving this address will be copied instead of overwritten
              </Typography>
            )}
            {["addr_street_address", "addr_city", "addr_state", "addr_postal_code", "addr_phone", "addr_country", "description"].map((a) => {
                const label = required[a] ? `${FRIENDLIES[a]}*` : FRIENDLIES[a];
                const mapped = map[a] ? map[a] : map.default;
                const EditComponent = mapped.component;
                if (!label) {
                    return <Input type="hidden" key={`${a}-key`} value={address[a]} />;
                }
                return (<EditComponent
                            key={`${a}-key`}
                            label={label}
                            style={inputStyle}
                            variant='outlined'
                            onChange={(e, o) => {
                                const value =  o ? o.value : _.get(e, 'target.value');
                                handleSetState(a, value);
                            } }
                            value={address[a]}
                        />)
            })}
        </React.Fragment>
    )
}

export default NewAddressInputs;
