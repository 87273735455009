import constants from '../constants';

const DEFAULT = {
  original: {},
  suggestion: {},
  loading: false,
  validated: false,
  currentAddress: {
    id: 0,
    addr_street_address: '',
    addr_city: '',
    addr_state: '',
    addr_postal_code: '',
    addr_country: '',
    addr_phone: '',
    description: ''
  },
}

const address = (state = DEFAULT, action) => {
  switch (action.type) {
    case 'VALIDATE_ADDRESS_BEGIN':
      return {
        ...state,
        original: action.address,
        loading: true,
      }
    case 'VALIDATE_ADDRESS_SUCCESS':
      return {
        ...state,
        suggestion: {
          ...state.original,
          ...action.address
        },
        loading: false,
      }
    case 'VALIDATE_ADDRESS_FAIL':
      return {
        ...state,
        loading: false,
        error: true,
      }
    case 'EDIT_ATTRIBUTE':
    case 'VALIDATE_ADDRESS_CANCEL': {
      return {
        ...DEFAULT
      };
    }
    case 'ADDRESS_CHANGE': {
      return {
        ...state,
        currentAddress: {
          ...state.currentAddress,
          [action.name]: action.value,
        },
        validated: false,
      }
    }
    case 'SAVE_ADDRESS': {
      return {
        ...state,
        currentAddress: {
          ...DEFAULT.currentAddress
        }
      }
    }
    case 'ADDRESS_EDIT': {
      return {
        ...state,
        currentAddress: {
          ...action.address,
        },
        validated: false
      }
    }
    case 'ADDRESS_SELECT_VALIDATED': {
      return {
        ...state,
        currentAddress: {
          ...state.currentAddress,
          ...state[action.key]
        },
        validated: true,
        original: {},
        suggestion: {},
        error: false,
      }
    }
    default:
      return state;
  }
}
export default address;
