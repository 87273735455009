import constants from '../constants';
import _ from 'lodash'
import isReducerType from "./isReducerType";

const DEFAULT = {
    init: false,
};

const curator = (state = DEFAULT, action) => {
    const isType = isReducerType(action);
    if (isType(constants.actions.APP_INITIALIZE_DONE)) {
        return {
            init: true
        }
    }

    if (isType(constants.actions.APP_INITIALIZE_PENDING)) {
        return {
            init: false
        }
    }

    return state;
};

export default curator;
