require("../lang");

class Constants {
    constructor() {
        this.actions = {
            APP_INITIALIZE_START: { type: "APP_INITIALIZE_START" },
            APP_INITIALIZE_PENDING: { type: "APP_INITIALIZE_PENDING" },
            APP_INITIALIZE_DONE: { type: "APP_INITIALIZE_DONE" },
            FETCH_ORG_ATTRIBUTES: { type: "FETCH_ORG_ATTRIBUTES" },
            FETCH_ORG_ADDRESSES: { type: "FETCH_ORG_ADDRESSES" },
            FETCH_ORG_BEGIN: { type: "FETCH_ORG_BEGIN" },
            SET_ORG: { type: "SET_ORG" },
            FETCH_ORG_SUCCESS: { type: "FETCH_ORG_SUCCESS" },
            FETCH_ORG_ADDRESSES_SUCCESS: { type: "FETCH_ORG_ADDRESSES_SUCCESS" },
            FETCH_ORG_ERROR: { type: "FETCH_ORG_ERROR" },
            FETCH_ORG_PENDING: { type: "FETCH_ORG_PENDING" },
            TOGGLE_SPINNER: { type: "TOGGLE_SPINNER" },
            FETCH_TASKS_BEGIN: { type: "FETCH_TASKS_BEGIN" },
            FETCH_TASKS_SUCCESS: { type: "FETCH_TASKS_SUCCESS" },
            FETCH_TASKS_ERROR: { type: "FETCH_TASKS_ERROR" },
            ASSIGN_TASK: { type: "ASSIGN_TASK" },
            ASSIGN_TASK_BULK: { type: "ASSIGN_TASK_BULK"},
            ASSIGN_TASK_SUCCESS: { type: "ASSIGN_TASK_SUCCESS" },
            ASSIGN_TASK_ERROR: { type: "ASSIGN_TASK_ERROR" },
            EDIT_ATTRIBUTE: { type: "EDIT_ATTRIBUTE" },
            PROMOTE_ATTRIBUTE: { type: "PROMOTE_ATTRIBUTE" },
            DEMOTE_ATTRIBUTE: { type: "DEMOTE_ATTRIBUTE" },
            PROMOTE_ADDRESS: { type: "PROMOTE_ADDRESS" },
            DEMOTE_ADDRESS: { type: "DEMOTE_ADDRESS" },
            REMOVE_ATTRIBUTE: { type: "REMOVE_ATTRIBUTE" },
            CLEAR_EDITOR: { type: "CLEAR_EDITOR" },
            UPDATE_EDITOR_ATTRIBUTE: { type: "UPDATE_EDITOR_ATTRIBUTE" },
            NEW_ADDRESS: { type: "NEW_ADDRESS" },
            CREATE_ATTRIBUTE: { type: "CREATE_ATTRIBUTE" },
            SAVE_ADDRESS: { type: "SAVE_ADDRESS" },
            SAVE_EDITS: { type: "SAVE_EDITS" },
            FETCH_SOURCES_SUCCESS: { type: "FETCH_SOURCES_SUCCESS" },
            SET_ACTIVE_PAGE: { type: "SET_ACTIVE_PAGE" },
            SET_ACTIVE_TAB: { type: "SET_ACTIVE_TAB" },
            CURATOR_LOADED: { type: "CURATOR_LOADED" },
            ORG_SOURCES_LOADED: { type: "ORG_SOURCES_LOADED" },
            USER_NOT_FOUND_IN_SOURCES: { type: "USER_NOT_FOUND_IN_SOURCES" },
            ADD_SOURCE_TO_USER: { type: "ADD_SOURCE_TO_USER" },
            ADD_SOURCE_TO_USER_ERROR: { type: "ADD_SOURCE_TO_USER_ERROR" },
            SET_CURRENT_TASK: { type: "SET_CURRENT_TASK" },
            FETCH_TASKS: { type: "FETCH_TASKS" },
            FETCH_TASK: { type: "FETCH_TASK" },
            EDITOR_LOADED: { type: "EDITOR_LOADED" },
            SET_ORG_RELATION: { type: "SET_ORG_RELATION" },
            FRIENDLIES_LOADED: { type: "FRIENDLIES_LOADED" },
            USER_LOADED: { type: "USER_LOADED" },
            CLASSIFICATION_LIST: { type: "CLASSIFICATION_LIST" },
            CLASSIFICATIONS_LOADED: { type: "CLASSIFICATIONS_LOADED" },
            ORG_MERGE_SUCCESS: { type: "ORG_MERGE_SUCCESS" },
            SUBMIT_MERGE: { type: "SUBMIT_MERGE" },
            DISABLE_MERGE: { type: "DISABLE_MERGE" },
            ENABLE_MERGE: { type: "ENABLE_MERGE" },
            RELATIONS_LOADING: { type: "RELATIONS_LOADING" },
            REVERT_MERGE: { type: "REVERT_MERGE" },
            ORG_REVERT_SUCCESS: { type: "ORG_REVERT_SUCCESS" },
            CLEAR_RELATION_EDITOR: { type: "CLEAR_RELATION_EDITOR" },
            TASK_LOADING: { type: "TASK_LOADING" },
            ADD_ITEM_TO_CORRAL: { type: "ADD_ITEM_TO_CORRAL" },
            REMOVE_ITEM_FROM_CORRAL: { type: "REMOVE_ITEM_FROM_CORRAL" },
            SEARCH: { type: "SEARCH" },
            SEARCH_PENDING: { type: "SEARCH_PENDING" },
            SEARCH_SUCCESS: { type: "SEARCH_SUCCESS" },
            SEARCH_ERROR: { type: "SEARCH_ERROR" },
            SHOW_SNACKBAR: { type: "SHOW_SNACKBAR" },
            HIDE_SNACKBAR: { type: "HIDE_SNACKBAR" },
            CLEAR_SEARCH: { type: "CLEAR_SEARCH" },
            DELETE_ORG: { type: "DELETE_ORG" },
            CREATE_ORG: { type: "CREATE_ORG" },
            CORRAL_SET_PARENT: { type: "CORRAL_SET_PARENT" },
            CORRAL_SET_ORG_RELATION: { type: "CORRAL_SET_ORG_RELATION" },
            CORRAL_SUBMIT_CHANGES: { type: "CORRAL_SUBMIT_CHANGES" },
            CORRAL_REVERT_CHANGES: { type: "CORRAL_REVERT_CHANGES" },
            CORRAL_MERGE_SUCCESS: { type: "CORRAL_MERGE_SUCCESS" },
            CORRAL_MERGE_FAIL: { type: "CORRAL_MERGE_FAIL" },
            CORRAL_REVERT_SUCCESS: { type: "CORRAL_REVERT_SUCCESS" },
            CLEAR_CORRAL: { type: "CLEAR_CORRAL" },
            CREATE_STOCK: { type: "CREATE_STOCK" },
            PROMOTE_STOCK: { type: "PROMOTE_STOCK" },
            DEMOTE_STOCK: { type: "DEMOTE_STOCK" },
            VALIDATE_ADDRESS_BEGIN: { type: "VALIDATE_ADDRESS_BEGIN" },
            VALIDATE_ADDRESS_SUCCESS: { type: "VALIDATE_ADDRESS_SUCCESS" },
            VALIDATE_ADDRESS_FAIL: { type: "VALIDATE_ADDRESS_FAIL" },
            VALIDATE_ADDRESS_CANCEL: { type: "VALIDATE_ADDRESS_CANCEL" },
            ADDRESS_CHANGE: { type: "ADDRESS_CHANGE" },
            ADDRESS_EDIT: { type: "ADDRESS_EDIT" },
            ADDRESS_SELECT_VALIDATED: { type: "ADDRESS_SELECT_VALIDATED" },
            DELETE_REPEATED_ATTRIBUTE: { type: "DELETE_REPEATED_ATTRIBUTE" },
            FETCH_RELATIONS_DONE: { type: "FETCH_RELATIONS_DONE" },
            FETCH_STARTED: { type: "FETCH_STARTED" },
            FETCH_ENDED: { type: "FETCH_ENDED" },
            UNMERGE_ORG: { type: "UNMERGE_ORG" },
            FETCH_ALL_ORG_DATA: { type: "FETCH_ALL_ORG_DATA" },
            DEACTIVATE_ORG: { type: "DEACTIVATE_ORG" },
            ACTIVATE_ORG: { type: "ACTIVATE_ORG" },
            FETCH_GROUPS_SUCCESS: { type: "FETCH_GROUPS_SUCCESS" },
            FATAL_ERROR: { type: "FATAL_ERROR" },
            UNAUTHORIZED: { type: "UNAUTHORIZED" },
            AUTHORIZATION_SUCCESS: { type: "AUTHORIZATION_SUCCESS" },
            CURRENT_GROUP_SELECTED: { type: "CURRENT_GROUP_SELECTED" },
            DELETE_ORG_SUCCEEDED: { type: "DELETE_ORG_SUCCEEDED" },
            DELETE_ORG_FAILED: { type: "DELETE_ORG_FAILED" },
            TASK_STATUS_UPDATED: { type: "TASK_STATUS_UPDATED" },
            NEXT_TASK: { type: "NEXT_TASK" },
            TASK_STATUS_UPDATE_SUCCESS: { type: "TASK_STATUS_UPDATE_SUCCESS" },
            TASK_STATUS_UPDATE_FAIL: { type: "TASK_STATUS_UPDATE_FAIL" },
            USER_AUTHENTICATED: { type: "USER_AUTHENTICATED" },
            REQUEST_FETCH_ORG_IN_PROGRESS: { type: "REQUEST_FETCH_ORG_IN_PROGRESS"},
            ORG_DIVORCE_PARENT: { type: "ORG_DIVORCE_PARENT" },
            SPINNER_ON: { type: "SPINNER_ON" },
            SPINNER_OFF: { type: "SPINNER_OFF" },
        };

        Object.keys(this.actions).map(
            key => {
                this.actions[key].getObj = args => ({ type: this.actions[key].type, ...args});
                this.actions[key].equals = args => args.type ? args.type === this.actions[key].type : args === this.actions[key].type;
            }
        );

        this.curator = {};
        this.curator.taskStatus = {
            TO_DO: "to_do",
            IN_PROGRESS: "in_progress",
            DONE: "done",
            PUNTED: "punted" ,
        };
        this.curator.defaultConfidences = {
            HUMAN: 0.9
        }
    }
};

global.constants = new Constants();

const constants = new Constants();
export default constants;
