import _ from "lodash"

const PUT = 'put'
const POST = 'post'
const DELETE = 'delete'

const getRequestBody = ({method, data, options}) => {
    return _.merge({},{
        method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'authorization': `Bearer ${global.USER.user.token}`,
        },
        body: JSON.stringify(data)
    }, options);
};

export const Api = {
    get: (url, options) => callApi(url, { ...options, headers: { 'authorization': `Bearer ${global.USER.user.token}` }}),
    post: (url, data, options) => callApi(url, getRequestBody({method: POST, data, options})),
    put: (url, data, options) => callApi(url, getRequestBody({method: PUT, data, options})),
    delete: (url, data, options) => callApi(url, getRequestBody({method: DELETE, data, options}))
};

function callApi(url, options) {
    return fetch(url, {
        method: 'GET',
        ...options,
    })
    .then(response => {
        if(response.status !== 204)
            return response.json().then(json => ({ json, response }));

        return {json: '', response};
    })
    .then(({ json, response }) => {
        if (!response.ok || json.status > 299 ) {
            return Promise.reject(json)
        }
        return json
    })
    .then(
        response => ({data: response}),
        error => ({error: error.message || 'Api call failed'})
    ).catch(err => {
        console.error('err', err);

    })

}
