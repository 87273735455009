export default {
    sanitizeWebsite: (url_attr) => {
        if (!url_attr) {
            return '---';
        }
        const currentWebsite = url_attr.values || url_attr;
        if (_.startsWith(currentWebsite, 'http://') || _.startsWith(currentWebsite, 'https://')) {
            return currentWebsite;
        }
        else {
            return 'http://' + currentWebsite;
        }
    }
}