import _ from "lodash";
import {Table, Icon} from 'semantic-ui-react'
import React, {useState} from 'react'
import AddressDisplay from "./AddressDisplay";
import ConfirmationPrompt from '../../../common/ConfirmationPrompt';
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const AddressRow = ({isSelected, address, onClickPromote, onClickDemote, onClickEdit}) => {
    const [promptOpen, setPromptOpen] = useState(false);

    const itemStyle = {
        cursor: 'pointer',
        float: "right",
        paddingLeft: '4px',
        paddingRight: '4px',
        marginLeft: '2px',
    };

    const handleDemote = () => {
        setPromptOpen(false);
        onClickDemote(address);
    }

    return (
        <>
            <TableRow>
                <AddressDisplay address={address} showPopup={true}/>
                <TableCell>
                    <ConfirmationPrompt
                        open={promptOpen}
                        onClose={() => setPromptOpen(false)}
                        onConfirm={handleDemote}
                        text="Are you sure you want to demote this address?"
                    />
                    {!isSelected &&
                    <Button
                        onClick={onClickPromote}
                        style={itemStyle}
                    >
                        Promote
                    </Button>
                    }
                    {isSelected &&
                    <Icon
                        name="star"
                        style={{float: "right"}}
                    />
                    }
                    <Button
                        onClick={() => setPromptOpen(true)}
                        style={itemStyle}
                    >
                        Demote
                    </Button>
                    <Button
                        onClick={() => onClickEdit(address)}
                        style={itemStyle}
                    >
                        Edit
                    </Button>
                </TableCell>
            </TableRow>
        </>
    );
};

export default AddressRow;
