import {all, put, takeLeading, takeLatest, delay} from 'redux-saga/effects'
import constants from '../constants'
import _ from "lodash"
import putAction from "./putAction";

export default function* () {
    yield all([
        takeLatest(constants.actions.FETCH_ENDED.type, spinnerOff),
        takeLeading(constants.actions.FETCH_STARTED.type, spinnerOn),
    ])
}

function* spinnerOff(action) {
    yield delay(500);
    yield putAction(constants.actions.SPINNER_OFF);
}

function* spinnerOn(action) {
    yield putAction(constants.actions.SPINNER_ON)
}
