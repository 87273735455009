import React from 'react';
import Button from '@material-ui/core/Button';

const AddButton = ({ handleNewAttribute, text }) => {
  const label = text || 'Add';
  return (
      <Button
          color='primary'
          variant='contained'
          style={{ float: 'right', 'paddingTop': '8px' }}
          onClick={handleNewAttribute}
      >{label}</Button>
  )
};

export default AddButton;
