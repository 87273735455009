import React from "react"
import {ConnectedRouter as Router} from 'connected-react-router'
import {Provider, ReactReduxContext} from 'react-redux'
require("../lang");
import configureStore, {history} from '../configureStore'
import MainContent from './MainContent'
import responsiveFontSizes from "@material-ui/core/styles/responsiveFontSizes";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import {useAuth0} from './react-auth0-wrapper';
import blue from '@material-ui/core/colors/blue';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import LinearProgress from "@material-ui/core/LinearProgress";


const theme = responsiveFontSizes(createMuiTheme({
    palette: {
        primary: {main: blue[900]},
        secondary: {main: green[700]},
        error: {main: red[700]},
    },
    color: {
        attention: {light: orange[400], main: orange[600], dark: orange[700]},
        halt: {light: red[400], main: red[600], dark: red[700]},
        proceed: green[600],
        displayOnly: grey[500]
    },
}));


const App = (props) => {
    const {isAuthenticated, user} = useAuth0();

    if(!isAuthenticated){
        return (<LinearProgress />);
    }

    return (
        <Provider store={configureStore(props)} context={ReactReduxContext}>
            <Router history={history} context={ReactReduxContext}>
                <ThemeProvider theme={theme}>
                    <MainContent/>
                </ThemeProvider>
            </Router>
        </Provider>
    );
};

export default App
