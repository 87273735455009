import _ from "lodash";
import { Table, Icon } from "semantic-ui-react";
import React from "react";
import StockInfoRow from "./StockInfoRow";

class StockList extends React.Component {

    handlePromoteStock = (att) => {
        return () => {
            this.props.promoteStock(att, this.props.org);
        }
    };

    handleDemoteStock = (attr) => {
        return () => {
            this.props.demoteStock(attr, this.props.org);
        }
    };

    render() {
        const attributes = this.props.attributes;
        const isSelected = (at) => _.isEqual(attributes[0], at);
        return (
            <div style={{ paddingTop: "20px" }}>
                <br />
                <Table celled compact='very' padded={true}>
                    <Table.Body>
                        {attributes.map((att) => {
                            return (
                            <StockInfoRow
                                key={`stock-info-${att.id}`}
                                onClickPromote={this.handlePromoteStock(att)}
                                onClickDemote={this.handleDemoteStock(att)}
                                isSelected={isSelected(att)}
                                attribute={att}
                            />)
                        })}
                    </Table.Body>
                </Table>
            </div>
        );
    }
}

export default StockList;
