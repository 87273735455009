import { all, call, takeEvery, put, select, take } from 'redux-saga/effects'
import constants from '../constants'
import { Api } from '../services/api'
import putAction from "./putAction";

export default function* () {
    yield all([
        takeEvery(constants.actions.VALIDATE_ADDRESS_BEGIN.type, validateAddress),
    ])
}


function* validateAddress(action) {
    const { data, error } = yield call(
      Api.put,
      `/api/v1/address/validate`,
      action.address
    );

    if (data) {
        yield putAction(constants.actions.VALIDATE_ADDRESS_SUCCESS, {
            address: data
        })
    } else {
        yield putAction(constants.actions.VALIDATE_ADDRESS_FAIL)
    }
}
