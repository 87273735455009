import React from "react"
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import helpers from './helpers';
import CorralHeader from './CorralHeader';
import CorralButton from '../CorralButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

const headers = [
    { id: 'parent', disablePadding: true, label: 'Set Parent' },
    { id: 'details', disablePadding: false, label: 'Details' },
    { id: 'links', disablePadding: false, label: 'Links' },
    { id: 'actions', disablePadding: false, label: 'Actions' }
];

const CorralEditor = ({ corralItems, setParent, setRelation, submitMerge, revertMerge, submitted, error }) => {
    const classes = useStyles();
    return (
        <div classes={classes.root}>
            <CorralHeader
                submitMerge={submitMerge}
                corralItems={corralItems}
                revertMerge={revertMerge}
                submitted={submitted}
                error={error}
            />
            <Table>
                <TableHead>
                    <TableRow>
                        {headers.map(header => (
                            <TableCell key={header.id} align='left' padding={header.disablePadding ? 'none' : 'default'}>
                                {header.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {corralItems.map((item) => {
                        const attributes = item.attributes;
                        const name = attributes.org_name.top ? attributes.org_name.top.values : '---';
                        const classification = attributes.org_classification.top ? attributes.org_classification.top.values : "unclassified";
                        const address = attributes.address.top ? attributes.address.top.values : {};
                        const website = attributes.org_domain.top ? attributes.org_domain.top.values : null;
                        const linkedIn = attributes.org_linkedin_url.top ? attributes.org_linkedin_url.top.values : null;
                        return (
                            <TableRow key={item.id}>
                                <TableCell>
                                    <input type="radio" disabled={submitted} defaultChecked={item.selectedParent} name="parentGroup" onChange={() => setParent(item)} />
                                </TableCell>
                                <TableCell>
                                    <h3>{name}</h3>
                                    <div>
                                        {classification}<br />
                                        {address.addr_city || "---"}, {address.addr_country || "---"}<br />
                                        <Link to={`/org/edit/${item.id}`}>edit</Link>
                                        <CorralButton item={item} />
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <a target="_blank" href={helpers.sanitizeWebsite(website)}>{website}</a><br />
                                    <a target="_blank" href={linkedIn}>{linkedIn}</a>
                                </TableCell>
                                <TableCell>
                                    <FormControl>
                                        <InputLabel id='action-drop'>Relationship</InputLabel>
                                        <Select
                                            labelId='action-drop'
                                            value={item.relation}
                                            className="mergeDropdown center"
                                            disabled={item.selectedParent || submitted}
                                            onChange={(e) => setRelation(item, e.target.value)}>
                                                <MenuItem defaultValue="Select">Select Action</MenuItem>
                                                <MenuItem value="self">Merge (only if exact same org)</MenuItem>
                                                <MenuItem value="acquired">Acquired</MenuItem>
                                                <MenuItem value="subsidiary">Subsidiary</MenuItem>
                                                <MenuItem value="division">Division</MenuItem>
                                                <MenuItem value="department">Department</MenuItem>
                                                <MenuItem value="campus">Campus</MenuItem>
                                                <MenuItem value="business unit">Business Unit</MenuItem>
                                        </Select>
                                    </FormControl>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </div>
    )
};

export default CorralEditor;
