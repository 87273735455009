import React from 'react';
import { emphasize, withStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import { Link as ReactLink } from 'react-router-dom'
import Link from '@material-ui/core/Link';

const StyledBreadcrumb = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.grey[100],

        color: theme.palette.grey[800],
        '&:hover, &:focus': {
            backgroundColor: theme.palette.grey[300],
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(theme.palette.grey[300], 0.12),
        },
    },
}))(Chip);

const RouterLink = props => <Link {...props} component={ReactLink} />;

export default function OrgRelationBreadCrumbs({org}) {
    const anchorRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = event => {

        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const ChildLabel = ({org}) => {
        const label = `${org.attributes.org_name.top.values} - ${org.relationToParent}`;
        const inactive = org.active ? '' : ' (disabled)';
        return (
            <RouterLink to={`/org/edit/${org.id}`}>
                {label}{inactive}
            </RouterLink>
        )
    };

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
            {!_.isEmpty(org.parent) && org.relationToParent !== 'self' && (
                <RouterLink to={`/org/edit/${org.parent.id}`}>{`${org.parent.attributes.org_name.top.values} (Parent)`}</RouterLink>
            )}
                <StyledBreadcrumb
                    label={`Children (${org.children.length})`}
                    deleteIcon={<ExpandMoreIcon />}
                    onClick={handleToggle}
                    ref={anchorRef}
                />
            </Breadcrumbs>
            <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    dense
                                    disablePadding
                                    autoFocusItem={open}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                    ListProps={{
                                        style: {
                                            maxHeight: 48 * 4.5,
                                            width: 200,
                                        },
                                    }}>
                                    {org.children.map((child) => {
                                        return <MenuItem dense onClick={handleClose}>
                                            <ChildLabel org={child} />
                                        </MenuItem>
                                    })}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}
