import {all, call, takeEvery, put, take} from 'redux-saga/effects'
import constants from '../constants'
import {Api} from '../services/api'
import {getAllOrgData, getAllAttributes, deleteOrg} from '../client/orgs';
import {push} from 'connected-react-router'
import putAction from "./putAction";


export default function* () {
    yield all([
        takeEvery(constants.actions.FETCH_ORG_ATTRIBUTES.type, fetchAttributes),
        takeEvery(constants.actions.FETCH_ORG_BEGIN.type, fetchOrg),
        takeEvery(constants.actions.FETCH_ALL_ORG_DATA.type, fetchAllOrgData),
        takeEvery(constants.actions.CREATE_ORG.type, createOrg),
        takeEvery(constants.actions.DELETE_ORG.type, doDeleteOrg),
    ])
}

function* doDeleteOrg(action) {
    yield call(deleteOrg, action.org);
    yield putAction(constants.actions.DELETE_ORG_SUCCEEDED);
    yield put(push(`/`));
}

function* createOrg(action) {
    const {data, error} = yield call(
        Api.put,
        `/api/v1/org`,
        {source_id: global.USER.sourceId});
    yield put(push(`/org/edit/${data}`));
}

function* fetchOrg(action) {
    yield putAction(constants.actions.FETCH_ORG_PENDING);
    yield putAction(constants.actions.FETCH_STARTED);
    const {data, error} = yield call(
        Api.get,
        `/api/v1/org/${action.orgId}/attributes/top`
    );
    if (error) {
        yield putAction(constants.actions.FETCH_ORG_ERROR,{ error });
        yield putAction(constants.actions.FETCH_ENDED);
    } else {
        const payload = data.map((attr) => {
            return {
                ...attr,
                value: attr.values
            }
        });
        yield putAction(constants.actions.FETCH_ORG_SUCCESS,{
            payload,
            orgId: action.orgId
        });
        yield putAction(constants.actions.FETCH_ENDED);
    }
}

function* fetchAllOrgData(action) {
    yield putAction(constants.actions.FETCH_STARTED);
    const {orgId} = action;
    try {
        const org = yield call(getAllOrgData, orgId);
        yield putAction(constants.actions.SET_ORG,{ org });
        yield putAction(constants.actions.FETCH_ENDED);
    } catch (error) {
        console.error(error);
        yield putAction(constants.actions.FETCH_ENDED);
        yield putAction(constants.actions.FETCH_ORG_ERROR,{ error });
    }
}

function* fetchAttributes(action) {
    yield putAction(constants.actions.FETCH_STARTED);
    const {orgId} = action;
    try {
        const org = yield call(getAllOrgData, orgId, {currentCount: 0, limit: 1});
        yield putAction(constants.actions.SET_ORG,{ org });
        yield putAction(constants.actions.FETCH_ENDED);
    } catch (error) {
        console.error(error);
        yield putAction(constants.actions.FETCH_ENDED);
        yield putAction(constants.actions.FETCH_ORG_ERROR,{ error });
    }
}
