import {Table} from "semantic-ui-react";
import React, {useState} from "react";
import ConfirmationPrompt from '../../../common/ConfirmationPrompt'
import StockInfoDisplay from "./StockInfoDisplay";
import EditorButtonGroup from "../controls/EditorButtonGroup";

const StockInfoRow = ({isSelected, attribute, onClickPromote, onClickDemote}) => {
    const [promptOpen, setPromptOpen] = useState(false);

    const handleDemote = () => {
        setPromptOpen(false);
        onClickDemote(attribute);
    };

    return (
        <Table.Row>
            <Table.Cell>
                <ConfirmationPrompt
                    open={promptOpen}
                    onClose={() => setPromptOpen(false)}
                    onConfirm={handleDemote}
                    text="Are you sure you want to demote this stock info?"
                />
                <StockInfoDisplay attr={attribute}/>
                <EditorButtonGroup
                    isSelected={isSelected}
                    attribute={attribute}
                    onPromote={onClickPromote}
                    onDemote={handleDemote}
                    onDeleteRepeated={handleDemote}
                    onDelete={handleDemote}
                />
            </Table.Cell>
        </Table.Row>
    )
};

export default StockInfoRow;
