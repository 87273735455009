import _ from "lodash";
import {Table, TableBody } from '@material-ui/core'
import React from "react";
import AttributeFormCell from "./AttributeFormCell";

const AttrList = ({
      promoteAttribute,
      removeAttribute,
      demoteAttribute,
      deleteRepeatedAttribute,
      attributes,
      org,
      currentlyEditing,
      setCurrentlyEditing,
      DisplayComponent,
    }) => {

  const onPromote = (newTopAttr) => {
    return () => {
      promoteAttribute(newTopAttr, org);
    }
  };

  const onDelete = (deletedAttr) => {
      return () => {
          removeAttribute(deletedAttr, org)
      }
  };

  const onDemote = (demotedAttr) => {
      return () => {
          demoteAttribute(demotedAttr, org)
      }
  };

  const onDeleteRepeated = (deletedAttr) => {
      return () => {
          deleteRepeatedAttribute(deletedAttr, org)
      }
  };

  const isSelected = (at) => _.isEqual(attributes.top, at);
  return (
    <div style={{paddingTop: "20px"}}>
      <br/>
      <Table>
        <TableBody>
          {attributes.map((a) => {
            return (
              <AttributeFormCell
                currentlyEditing={currentlyEditing}
                setCurrentlyEditing={setCurrentlyEditing}
                key={a.id}
                onPromote={onPromote(a)}
                onDemote={onDemote(a)}
                onDelete={onDelete(a)}
                onDeleteRepeated={onDeleteRepeated(a)}
                isSelected={isSelected(a)}
                attribute={a}
                DisplayComponent={DisplayComponent}
              />)
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default AttrList;
