import React from "react";
import AttributeTable from "../attributes/AttributeTable";

class OrgView extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const org = this.props.org
        if (!org ) {
            return null;
        }
        if (org.error) {
            return (
                <React.Fragment>
                    <div>An error occurred</div>
                </React.Fragment>
            );
        }

        return (
            <AttributeTable
                org={org}
                onSelect={this.props.editAttribute}
                createAttribute={this.props.createAttribute}
            />
        );

    }
}

export default OrgView;
