import constants from '../constants';
import _ from 'lodash'
import Organization from '../model/Organization'
import isReducerType from "./isReducerType";

const corral = (state = { corralItems: [] }, action) => {
    const isType = isReducerType(action);
    if (isType(constants.actions.ADD_ITEM_TO_CORRAL)) {
        let corralItems = _.clone(state.corralItems);
        const newOrg = new Organization(action.item);
        if (!_.find(corralItems, newOrg)) {

            if(!_.find(corralItems, 'selectedParent')){
                newOrg.selectedParent = true;
                newOrg.relation = 'none';
            }else{
                newOrg.selectedParent = false;
            }

            corralItems.push(newOrg);

            if (newOrg.children) {
                newOrg.children.map(c => {
                    c.selectedParent = false;
                    c.relation = c.relationToParent;
                    corralItems.push(c)
                });
            }

            return {
                ...state,
                corralItems: _.uniqBy(corralItems, 'id')
            }
        }
        return state;
    }

    if (isType(constants.actions.REMOVE_ITEM_FROM_CORRAL)) {
        return {
            ...state,
            corralItems: _.remove(state.corralItems, (i) => i.id !== action.item.id)
        }
    }

    if (isType(constants.actions.CORRAL_SET_PARENT)) {
        let corralItems = _.clone(state.corralItems);
        return {
            ...state,
            corralItems: corralItems.map((i) => {
                i.selectedParent = i.id === action.newParent.id;
                i.relation = i.id === action.newParent.id;
                if (i.parent[0] && i.parent[0].id == action.newParent.id) {
                   i.relation = i.relationToParent;
                }
                return i;
            })
        }
    }

    if (isType(constants.actions.CORRAL_SET_ORG_RELATION)) {
        const org = action.org;
        let corralItems = _.clone(state.corralItems);
        return {
            ...state,
            corralItems: corralItems.map((i) => {
                if (i.id === org.id) {
                    i.relation = action.relation;
                }
                return i;
            })
        }
    }

    if (isType(constants.actions.CORRAL_MERGE_SUCCESS)) {
        return {
            ...state,
            submitted: true,
            error: false,
        }
    }

    if (isType(constants.actions.CORRAL_MERGE_FAIL)) {
        return {
            ...state,
            submitted: false,
            error: true
        }
    }

    if (isType(constants.actions.CLEAR_CORRAL)) {
        return {
            corralItems: [],
            submitted: false
        }
    }

    if (isType(constants.actions.CORRAL_REVERT_SUCCESS)) {
        const corralItems = _.clone(state.corralItems);
        corralItems.map((i) => {
            i.relation = false;
            i.parent = false;
            return i;
        })
        return {
            ...state,
            submitted: false
        }
    }

    return state;
};

export default corral;
