import React, {useState} from "react"
import {Route, withRouter} from 'react-router-dom'
import {connect} from "react-redux"
import constants from "../../../constants"
import EditorView from "../editor/EditorView";
import TaskInstruction from "./TaskInstructions"
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import {Typography} from "@material-ui/core";
import Select from '@material-ui/core/Select';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {Alert, AlertTitle} from '@material-ui/lab';
import { useHistory } from "react-router-dom";

const TaskDescription = ({type}) => {
    const instructions = TaskInstruction[type] ? TaskInstruction[type] : type;
    return (
        <Alert severity="info">
            <AlertTitle>Task Info</AlertTitle>
            <Typography variant={"subtitle1"}>
                {instructions}
            </Typography>
        </Alert>
    )
};

const taskStatuses = ['to_do', 'in_progress', 'punted', 'done'];

const TaskView = ({task, setTaskStatus, list, taskStatusIdx}) => {

    if (task.loading) {
        return null;
    }

    const [taskStatus, setStatusState] = useState(task.status);
    const history = useHistory();

    const onSelect = (e) => {
        setStatusState(e.target.value);
        setTaskStatus(task, e.target.value);
    };

    const inputLabel = React.useRef(null);
    const filteredList = _.filter(list, t => t.status === taskStatuses[taskStatusIdx]);
    const currentIndex = _.findIndex(filteredList, i => i.id === task.id);
    const goToNextTask = () => {
        const {id, org_id} = filteredList[currentIndex + 1];
        history.push(`/task/${id}/edit/${org_id}`);
    };

    return (
        <>
            <div style={{textAlign: 'right'}}>
                <Typography>
                    Task {currentIndex + 1}/{filteredList.length}
                </Typography>
            </div>
            <Grid container direction='row' alignItems='center' justify="flex-start" size='large' spacing={3}>
                <Grid item xs={6}>
                    <Paper>
                        <Typography>
                            {task ? <TaskDescription type={task.task_type}/> : <></>}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Grid container direction='row' spacing={3} alignItems='center' justify="flex-end" size='large'>
                        <Grid item>
                            <FormControl variant='standard'>
                                <InputLabel ref={inputLabel} htmlFor="outlined-age-native-simple">
                                    Task Status
                                </InputLabel>
                                <Select
                                    native
                                    value={taskStatus}
                                    onChange={onSelect}
                                    inputProps={{
                                        name: 'task_status',
                                        id: 'outlined-age-native-simple',
                                    }}
                                >
                                    <option value="to_do">To Do</option>
                                    <option value="in_progress">In Progress</option>
                                    <option value="punted">Skipped</option>
                                    <option value="done">Done</option>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            {currentIndex < filteredList.length - 1 &&
                                <Button onClick={goToNextTask} color="secondary" variant="contained">
                                    Next
                                    <NavigateNextIcon/>
                                </Button>
                            }
                            {currentIndex >= filteredList.length - 1 &&
                                <Button onClick={() => { history.push('/')}} color="secondary" variant="contained">
                                    Home
                                </Button>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <br/>
            <Route matches path={"/task/:taskId/edit/:id"} component={EditorView}/>
        </>
    )
};

const mapStateToProps = (state, props) => {
    return {
        list: state.curationList.userList,
        taskStatusIdx: state.curationList.activeTabIndex,
        task: state.task,
        ...props
    }
};

const dispatchToProps = dispatch => ({
    setTaskStatus: (task, status) => dispatch(constants.actions.TASK_STATUS_UPDATED.getObj({
        task,
        status
    })),
});

export default withRouter(connect(mapStateToProps, dispatchToProps)(TaskView));
