import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import constants from "../../constants";
import React from "react";
import _ from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import Typography from "@material-ui/core/Typography";


const CorralButton = ({ addToCorral, item, corralItems, removeFromCorral, addText, removeText }) => {
  const id = item ? item.id : null;
  const inCorral = _.some(corralItems, ['id', id]);

  const handleAddClick = (e) => {
    addToCorral(item);
  };

  const handleRemoveClick = (e) => {
    removeFromCorral(item)
  };

  return (
    <>
    {!inCorral &&
      <Button
        color="primary"
        onClick={handleAddClick}
        aria-label="mark for review">
        <AddIcon />
        {!!addText &&
        <Typography>
          {addText}
        </Typography>
        }
      </Button>
    }
    {inCorral &&
      <Button
          onClick={handleRemoveClick}
          color="secondary"
          aria-label="mark for review">
        <ClearIcon />
        {!!removeText &&
        <Typography>
          {removeText}
        </Typography>
        }
      </Button>
    }
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    item: ownProps.item,
    corralItems: state.corral.corralItems
  }
};

const dispatchToProps = (dispatch) => ({
  addToCorral: (item) => {
    dispatch(constants.actions.ADD_ITEM_TO_CORRAL.getObj({
      item
    }));
    dispatch(constants.actions.SHOW_SNACKBAR.getObj({
      message: `${item.attributes.org_name.top.values} added for review`,
      variant: 'info'
    }));
  },
  removeFromCorral: (item) => {
    dispatch(constants.actions.REMOVE_ITEM_FROM_CORRAL.getObj({
      item
    }));
    dispatch(constants.actions.SHOW_SNACKBAR.getObj({
      message: `${item.attributes.org_name.top.values} removed from review`,
      variant: 'info'
    }));
  }
});

export default connect(mapStateToProps, dispatchToProps)(CorralButton);
