const requests = (state = { active: [] }, action) => {

  const isBeginRequest = /.*_BEGIN$/;
  const isEndRequest = /.*(_FAIL|_SUCCESS)$/

  if (isBeginRequest.test(action.type)) {
    return {
      active: [
        ...state.active,
        action.type
      ]
    }
  }

  if (isEndRequest.test(action.type)) {

    const matches = action.type.match(/(.*)(_FAIL|_SUCCESS)$/);
    const prefix = matches[1];
    return {
      active: state.active.filter((request) => !request.startsWith(prefix))
    }
  }
  return state
};

export default requests
