import { connect } from "react-redux";
import constants from "../../../constants";
import Container from '@material-ui/core/Container';
import React from "react";
import CorralEditor from './CorralEditor';

const Corral = (props) => {
    return (
        <Container>
            {props.corralItems && props.corralItems.length < 1 &&
                <h2>No Items for Review</h2>
            }
            {props.corralItems && props.corralItems.length > 0 &&
                <CorralEditor
                    corralItems={props.corralItems}
                    setParent={props.setParent}
                    setRelation={props.setRelation}
                    submitMerge={props.submitMerge}
                    revertMerge={props.revertMerge}
                    submitted={props.submitted}
                    error={props.error}
                />
            }
        </Container>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setParent: (newParent, currentParent) =>
            dispatch(constants.actions.CORRAL_SET_PARENT.getObj({
                newParent,
                currentParent
            })),
        setRelation: (org, relation) =>
            dispatch(constants.actions.CORRAL_SET_ORG_RELATION.getObj({
                org,
                relation
            })),
        submitMerge: (corralItems) =>
            dispatch(constants.actions.CORRAL_SUBMIT_CHANGES.getObj({
                corralItems
            })),
        revertMerge: (corralItems) =>
            dispatch(constants.actions.CORRAL_REVERT_CHANGES.getObj({
                corralItems
            }))
    }
};

const mapStateToProps = (state, props) => ({
    ...state.corral
})

export default connect(mapStateToProps, mapDispatchToProps)(Corral);
