import {attributeTypes} from "./attributeTypes";

function Attribute(attribute, key, type, orgId) {
    this.display = () => global.FRIENDLIES[key] || global.FRIENDLIES[this.name];
    this.orgId = parseInt(orgId);
    this.type = type || attributeTypes[key];
    this.apiKey = key;

    if(attribute) {
        this.name = attribute.name || key;
        this.id = parseInt(attribute.id);
        this.source_id = attribute.source_id;
        this.confidence = attribute.confidence;
        this.created_at = attribute.created_at;
        this.updated_at = attribute.updated_at;
        this.values = attribute.values;
    }
    this.toRequest = () => ({
        name: this.apiKey,
        value: this.values,
        source_id: this.source_id
    });
}

export default Attribute;
