import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Link} from "react-router-dom";
import TableCell from '@material-ui/core/TableCell';


const useStyles = makeStyles({
    card: {
        midWidth: 275,
        marginBottom: 12,
    },
    title: {
        fontSize: 14,
        fontWeight: 'bolder',
    },
    pos: {},
});

export default function RelationCard({org}) {

    const classes = useStyles();

    const {org_name, org_classification, address} = org.attributes;
    return (
        <>
            <TableCell>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    <Link to={`/org/edit/${org.id}`}>
                        {org_name.top.values}
                    </Link>
                </Typography>
                <Typography variant="body2" component="p">
                    {org_classification.top &&
                    org_classification.top.values
                    }
                    {address.top &&
                    `${address.top.values.addr_city}, ${address.top.values.addr_state}, ${address.top.values.addr_country}`
                    }
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="body2" component="p">
                    &nbsp;
                </Typography>
            </TableCell>
        </>
    );
}
