import isReducerType from "./isReducerType";

const spinner = (state = {}, action) => {
  const isType = isReducerType(action);

  if (isType(constants.actions.TOGGLE_SPINNER)) {
    return {
      ...state,
      open: action.open
    }
  }
  if( isType(constants.actions.SPINNER_ON)) {
    return {
      ...state,
      open: true
    }
  }
  if (isType(constants.actions.SPINNER_OFF)) {
    return {
      ...state,
      open: false,
    }
  }
  return state;
};

export default spinner;
