import React from "react"
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import UndoIcon from '@material-ui/icons/Undo';
import clsx from 'clsx';
import _ from 'lodash'

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },  
  iconSmall: {
    fontSize: 20,
  },
}));

export default function RevertButton({onClick, corralItems}) {  
  const classes = useStyles();
  
  return (
    <div>
      <Button variant="contained" size="small" className={classes.button} onClick={() => onClick(corralItems)}>
        <UndoIcon className={clsx(classes.leftIcon, classes.iconSmall)} />
        Revert
      </Button>
    </div>
  );
}