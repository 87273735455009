import {all, call, put, takeEvery} from 'redux-saga/effects'
import {activateOrg, deactivateOrg, unmerge} from "../client/orgs";
import constants from '../constants'
import putAction from "./putAction";

const EDIT = "edit";

export default function* () {
    yield all([
        takeEvery("@@router/LOCATION_CHANGE", resetEditor),
        takeEvery(constants.actions.UNMERGE_ORG.type, unmergeOrg),
        takeEvery(constants.actions.DEACTIVATE_ORG.type, doDeactivateOrg),
        takeEvery(constants.actions.ACTIVATE_ORG.type, doActivateOrg),
    ])
}

function* setActiveStatus(endpoint, action) {
    yield putAction(constants.actions.FETCH_STARTED);
    try {
        yield call(endpoint, action.org);
        yield putAction(constants.actions.FETCH_ORG_ATTRIBUTES, {orgId: action.org.id});
        yield putAction(constants.actions.FETCH_ENDED);
    } catch (error) {
        console.error(error);
        yield putAction(constants.actions.FETCH_ENDED);
        yield putAction(constants.actions.FETCH_ORG_ERROR, { error });
    }
}

function* doDeactivateOrg(action) {
    yield setActiveStatus(deactivateOrg, action);
}

function* doActivateOrg(action) {
    yield setActiveStatus(activateOrg, action);
}

function* resetEditor(action) {
    const pathParts = action.payload.location.pathname.split("/");
    if (pathParts.includes(EDIT)) {
        yield putAction(constants.actions.CLEAR_EDITOR);
    }
}

function* unmergeOrg(action) {
    yield call(unmerge, action.org);
    yield putAction(constants.actions.ORG_REVERT_SUCCESS);
    yield putAction(constants.actions.FETCH_ORG_ATTRIBUTES,{ orgId: action.org.id });
}


