import React from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const GroupSelector = ({onSelect, list, current}) => {

    const classes = useStyles();
    const currentId = _.isUndefined(current) ? '' : current.id;

    if(!list){
        return null;
    }

    const handleSelect = (e) => {
        const currentGroup = _.find(list, ['id', e.target.value]);
        onSelect(currentGroup);
    };

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Group</InputLabel>
            <Select
                labelid="demo-simple-select-label"
                id="demo-simple-select"
                value={currentId}
                onChange={handleSelect}
            >
                {list.map((gronp) => {
                    return <MenuItem key={gronp.id} value={gronp.id}>{gronp.name}</MenuItem>
                })}
            </Select>
        </FormControl>
    )

};

const mapStateToProps = (state, props) => {
    return {
        ...state.groups,
        onSelect: props.onSelect,
        current: props.current,
    }
};

const dispatchToProps = dispatch => (
    {
    }
);

export default connect(mapStateToProps, dispatchToProps)(GroupSelector);
