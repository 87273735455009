import Attribute from './Attribute'
import {attributeTypes} from './attributeTypes';

const combineAttrsByName = (attrs) => {
  return attrs.reduce((memo, current) => {
    if (memo[current.name]) {
      memo[current.name].push(current);
    } else {
      memo[current.name] = [current];
    }
    return memo;
  }, {});
};

export function Attributes(attrs, orgId) {
  const attributes = Array.isArray(attrs) ? combineAttrsByName(attrs) : attrs;
  this.address = attributeList(attributes, 'address', orgId);
  this.org_about = attributeList(attributes, 'org_about', orgId);
  this.org_description = attributeList(attributes, 'org_description', orgId);
  this.org_cb_url = attributeList(attributes, 'org_cb_url', orgId);
  this.org_classification = attributeList(attributes, 'org_classification', orgId);
  this.org_description = attributeList(attributes, 'org_description', orgId);
  this.org_domain = attributeList(attributes, 'org_domain', orgId);
  this.org_employee_count = attributeList(attributes, 'org_employee_count', orgId);
  this.org_funding_closed_on = attributeList(attributes, 'org_funding_closed_on', orgId);
  this.org_last_funding = attributeList(attributes, 'org_last_funding', orgId);
  this.org_linkedin_url = attributeList(attributes, 'org_linkedin_url', orgId);
  this.org_logo_url = attributeList(attributes, 'org_logo_url', orgId);
  this.org_name = attributeList(attributes, 'org_name', orgId);
  this.org_status = attributeList(attributes, 'org_status', orgId);
  this.stock = attributeList(attributes, 'stock', orgId);
  this.org_synonym = attributeList(attributes, 'org_synonym', orgId);
  this.revert = attributeList(attributes, 'revert', orgId);
  this.merged = this.revert.length > 0;
  this.merged_from = attributeList(attributes, 'merged_from', orgId);
  this.curation_status = attributeList(attributes, 'curation_status', orgId);
  this.org_logo_url = attributeList(attributes, 'org_logo_url', orgId);
  this.founded_on = attributeList(attributes, 'founded_on', orgId);
}

function attributeList(attributes, key, orgId) {
  const attribute = attributes[key] || [];
  let list = attribute.map((a) => new Attribute(a, key, attributeTypes[key], orgId)) || [];
  list.type = attributeTypes[key];
  list.orgId = orgId;
  list.display = () => global.FRIENDLIES[key] || key.titleize();
  list.top = list[0];
  list.apiKey = key;
  return list;
}

function Organization({id, domain, attributes, children, parent, relationToParent, active, merges}) {
  this.id = parseInt(id);
  this.domain = domain;
  this.attributes = new Attributes(attributes, this.id);
  this.children = children;
  this.parent = parent || {};
  this.active = active;
  this.relationToParent = getRelationToParent(relationToParent, parent, id);
  this.merges = merges;
}

const getRelationToParent = (relationToParent, parent, id) => {
  if(!!relationToParent) return relationToParent.toLowerCase();
  if(isOwnParent(parent, id)){
    return 'self'
  }
  return '';
};

const isOwnParent = (parent, id) => _.get(parent, 'id', NaN) === id;

export default Organization;

