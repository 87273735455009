import React from "react";
import DefaultEditor from "./DefaultEditor/DefaultEditor";
import AddressEditor from "./AddressEditor/AddressEditor";
import ClassificationEditor from "./ClassificationEditor/ClassificationEditor";
import UrlEditor from "./UrlEditor/UrlEditor";
import StockEditor from "./StockEditor/StockEditor";
import {dataTypes} from "../../../model/attributeTypes";
import _ from 'lodash';
import ImageEditor from "./ImageEditor/ImageEditor";
import DateEditor from "./DateEditor/DateEditor";

const EditorCard = (props) => {

  const ComponentToRender = ({ props }) => {
    const type = _.get(props, 'attribute.type.dataType', false);

    if(!type) {
      return <></>;
    }

    if (type === dataTypes.ADDRESS) {
      return <AddressEditor {...props} />
    } else if (type === dataTypes.MULTI) {
      return <ClassificationEditor {...props} />
    } else if (type === dataTypes.URL) {
      return <UrlEditor {...props} />
    } else if (type === dataTypes.STOCK) {
      return <StockEditor {...props} />
    } else if (type === dataTypes.IMAGE) {
      return <ImageEditor {...props} />
    } else if (type === dataTypes.DATE) {
      return <DateEditor {...props} />
    } else {
      return <DefaultEditor {...props} />
    }
  };

  return (
      <>
        <ComponentToRender
          props={props}
        />
        <br />
      </>
  );
};

export { EditorCard };
