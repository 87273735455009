import {all, call, spawn} from 'redux-saga/effects'
import {sourceSagas} from './sourceSagas'
import attributeSagas from './attributeSagas'
import orgSagas from './orgSagas'
import taskSagas from './taskSagas'
import editorSagas from './editorSagas'
import searchSagas from './searchSagas'
import corralSagas from './corralSagas'
import addressValidationSagas from './addressValidationSagas'
import initSagas from './initSagas'
import routeSagas from "./routeSagas";
import spinnerSagas from "./spinnerSagas";

export function* rootSaga() {
    const sagas = [
        initSagas,
        routeSagas,
        spinnerSagas,
        sourceSagas,
        attributeSagas,
        orgSagas,
        taskSagas,
        editorSagas,
        searchSagas,
        corralSagas,
        addressValidationSagas,
    ];
    // Prevents a crashed saga from killing all sagas and crashing the entire app.
    // @see https://redux-saga.js.org/docs/advanced/RootSaga.html

    yield all(sagas.map(saga =>
        spawn(function* () {
            let failures = 0;
            while (failures < 5) {
                try {
                    yield call(saga)
                    break
                } catch (e) {
                    console.error(e)
                    failures++
                }
            }
            console.error('Critical error caused, Sagas have been stopped.')
        })))
}
