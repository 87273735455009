import React, {Component} from 'react';
import {Input} from "semantic-ui-react";
import StockList from "./StockList";
import AddButton from "../controls/AddButton";
import StockExchange from "./StockExchange";

const DEFAULT_STATE = {
  symbol: '',
  exchange: ''
};

class StockEditor extends Component {

  constructor(props) {
    super(props)
    this.state = DEFAULT_STATE;
  }

  handleSetState = (newValue) => {
    this.setState({
      [newValue.name]: newValue.value
    })
  };

  shouldAddStockInfo = () => {
    return (
      !(_.isEmpty(this.state.symbol.trim()) &&
        _.isEmpty(this.state.exchange.trim()))
    )
  };

  handleNewAttribute = () => {
    if (this.shouldAddStockInfo()) {
      const newStock = {
        "symbol": this.state.symbol.trim(),
        "exchange": this.state.exchange.trim(),
        "orgId": this.props.org.id,
      };

      this.props.addNewStock(newStock);
      this.setState(DEFAULT_STATE);
    }
  };

  handleEdit = (symbol, exchange) => {
    this.setState({
      symbol: symbol,
      exchange: exchange,
    })

  };

  editComponent = () => {
    return (
      <>
        <Input
          placeholder={`Add new Stock Symbol`}
          style={{width: '80%', marginTop: '8px'}}
          onChange={(e) => {
            this.handleSetState({name: 'symbol', value: e.target.value});
          }}
        />
        {this.state.symbol === '' && <i><br/>Symbol is required</i>}
        <br/>
        <StockExchange
          onChange={(e, data) => {
            this.handleSetState({name: 'exchange', value: data.value});
          }}
          value={this.state.exchange}
        />
        {this.state.exchange === '' && <i><br/>Exchange is required</i>}
      </>
    )
  }

  render() {
    const {attribute, promoteStock, addNewAttribute, removeAttribute, create, org, demoteStock} = this.props
    return (
      <>
        {this.editComponent()}
        <AddButton handleNewAttribute={this.handleNewAttribute}/>
        {!create && attribute &&
        <StockList
          type={attribute.type}
          attributes={attribute}
          promoteStock={promoteStock}
          demoteStock={demoteStock}
          addNewAttribute={addNewAttribute}
          removeAttribute={removeAttribute}
          org={org}
        />
        }
      </>
    );
  }
}

export default StockEditor;
