import React from "react";
import AttributeMetaItems from "../AttributeMetaItems";

const ImageAttributeDisplay = ({attr}) => {
    return (
        <>
            <img style={{maxHeight: '200px'}} src={attr.values}  alt='logo' />
            <AttributeMetaItems attr={attr} />
        </>
    );
};

export default ImageAttributeDisplay;
