import React from 'react';
import AttrList from "../AttrList";
import AddButton from "../controls/AddButton";
import TextField from '@material-ui/core/TextField';
import useCurrentlyEditing from "../hooks/useCurrentlyEditing";
import useUrlValidation from "../UrlEditor/hooks/useUrlValidation";
import ImageAttributeDisplay from "./ImageAttributeDisplay";

const ImageEditor = ({attribute, org, addNewAttribute, promoteAttribute, removeAttribute, demoteAttribute, deleteRepeatedAttribute, create}) => {

    const [currentlyEditing, setCurrentlyEditing, onChange] = useCurrentlyEditing(org, attribute);
    const header = attribute.display();
    const addButtonText = global.USER.sourceId !== currentlyEditing.source_id ? 'Add' : 'Update';
    const [url, setUrl, isValid, ValidationAlert] = useUrlValidation(currentlyEditing.values);


    const handleNewAttribute = () => {
        if (isValid) {
            if (!_.isEmpty(currentlyEditing.values)) {
                addNewAttribute(currentlyEditing);
            }
        }
    };

    return (
        <>
            <ValidationAlert />
            <TextField
                key={attribute.name}
                value={currentlyEditing.values}
                placeholder={`Add new ${header}`}
                style={{width: '80%'}}
                onChange={(e) => {
                    onChange(e);
                    setUrl(e.target.value);
                }}
            />
            <AddButton handleNewAttribute={handleNewAttribute} text={addButtonText} />
            {!create && attribute &&
            <AttrList
                type={attribute.type}
                attributes={attribute}
                promoteAttribute={promoteAttribute}
                addNewAttribute={addNewAttribute}
                removeAttribute={removeAttribute}
                demoteAttribute={demoteAttribute}
                deleteRepeatedAttribute={deleteRepeatedAttribute}
                org={org}
                setCurrentlyEditing={setCurrentlyEditing}
                DisplayComponent={ImageAttributeDisplay}
            />
            }
        </>
    );
};

export default ImageEditor;
