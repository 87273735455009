import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from '@material-ui/core/Backdrop';
import React from "react";
import {connect} from "react-redux";
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000,
        color: '#fff',
    },
}));

const LoadingIndicator = ({loading}) => {

    const classes = useStyles();

    if (loading) {
        return (
            <Backdrop
                className={classes.backdrop}
                open={loading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        )
    }
    return (<></>);
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.spinner.open
    }
};

const dispatchToProps = () => {
    return {}
};

export default connect(mapStateToProps, dispatchToProps)(LoadingIndicator);

