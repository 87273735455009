import _ from 'lodash'

export const alertsInitialState = {
  byId: {},
  allIds: []
};

const alerts = (state = alertsInitialState, action) => {
  switch (action.type) {
    case "ADD_ALERT":
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.uuid]: action.payload
        },
        allIds: [
          ...state.allIds,
          action.payload.uuid
        ]
      }
    case "REMOVE_ALERT":
      return {
        ...state,
        byId: _.omit(state.byId, [action.uuid]),
        allIds: state.allIds.filter(alert => alert !== action.uuid)
      }
    default:
      return state;
  }
}

export default alerts
