import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './components/App';
import {Auth0Provider} from "./components/react-auth0-wrapper";
import * as Cookies from 'es-cookie'

const c = {};
try {
    const cookie = Cookies.get('api');
    c.cookieToken = cookie ? JSON.parse(cookie) : { token: null, user: {} };
} catch (e) {

}

global.USER = { user : {}};

ReactDOM.render(
    <Auth0Provider
        domain={'amplion-sso.auth0.com'}
        client_id={'WnHcOk9AbXo0F1A362jymqgQceaBkey3'}
        redirect_uri={window.location.origin}
        cookieToken={c.cookieToken}
        scope="openid email groups permissions roles"
        audience={'https://curator.amplion.com'}
    >
        <App />
    </Auth0Provider>,

  document.getElementById('root')
);

serviceWorker.unregister();
