import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import Spinner from './common/Spinner'
import Curator from './curator/Curator'
import { Switch, Route} from 'react-router'
import {useAuth0} from './react-auth0-wrapper';
import constants from "../constants";

const MainContent = ({appInitializing, isInitialized}) => {
    const {user} = useAuth0();
    useEffect(() => {
        if(!isInitialized){
            appInitializing(user);
        }
    },[isInitialized]);

    return (
        <React.Fragment>
            <Spinner/>
            <Switch>
                <Route matches path="/" component={Curator}/>
                <Route render={() => (<h1>NOT FOUND</h1>)}/>
            </Switch>
            <br/>
            <br/>
            <br/>
            <div style={{position: "fixed", bottom: '10px', left: '10px'}}>
                &copy; 2019 Amplion inc.
            </div>
            <br/>
            <br/>
        </React.Fragment>
    );
};

const dispatchToProps = dispatch => ({
    appInitializing: user => dispatch(constants.actions.APP_INITIALIZE_START.getObj({user}))
});
const mapStateToProps = (state, props) => ({
    isInitialized: state.curator.init
});

export default connect(mapStateToProps, dispatchToProps)(MainContent);
