import React from 'react'
import {Select} from 'semantic-ui-react';

export const StockExchange = (props) => {

    const options = [
        "AMEX",
        "BVMF",
        "NMFQS",
        "NYSE",
        "OTC",
        "V",
        "XBKK",
        "XBOM",
        "XCNQ",
        "XCSE",
        "XETR",
        "XFRA",
        "XHEL",
        "XHKG",
        "XIDX",
        "XJPX",
        "XJSE",
        "XKLS",
        "XKOS",
        "XKRX",
        "XLON",
        "XNAS",
        "XNYS",
        "XOSL",
        "XSES",
        "XSHE",
        "XSHG",
        "XSTO",
        "XSWX",
        "XTAE",
        "XTAI",
        "XTSX",
        "XWAR",
        "XWBO"
    ]

    const formattedOptions = options.map((item) => ({key: item, value: item, text: item}))

    return <Select options={formattedOptions} {...props} style={{marginTop: '8px'}}/>
}

export default StockExchange;
