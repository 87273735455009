import React, {useEffect} from "react";
import { List, Placeholder, Grid } from 'semantic-ui-react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const statusMap = {
    'to_do' : { color: 'secondary', text: 'Add'},
    'punted' : { color: 'secondary', text: 'Resume'},
    'in_progress' : { color: 'primary', text: 'Edit'},
    'done' : { color: 'default', text: 'Review'},
};

const AssignmentCheckbox = ({status, onClick, checked, task}) => {
    const handleClick = () => {
        onClick({
            user: !checked ? global.USER : {sourceId: 0},
            task
        });
    };
    const {color, text} = statusMap[status];
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={checked}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    onClick={handleClick}
                    color={color}
                />}
            label={text}
        />
        );
};

const TaskItem = ({org, fetchOrg, task, assignTask}) => {

    useEffect(() => {
        if(!org){
            fetchOrg(task.org_id)
        }
    }, [org, task]);

    if(!org) {
        return (
            <TableRow>
                <TableCell>
                    <Placeholder>
                        <Placeholder.Line/>
                    </Placeholder>
                </TableCell>
                <TableCell>
                    <Placeholder>
                        <Placeholder.Line/>
                    </Placeholder>
                </TableCell>
                <TableCell>
                    <Placeholder>
                        <Placeholder.Line/>
                    </Placeholder>
                </TableCell>
                <TableCell>
                    <Placeholder.Line/>
                </TableCell>
                <TableCell>
                    <Placeholder>
                        <Placeholder.Line/>
                    </Placeholder>
                </TableCell>
            </TableRow>
        )
    }

    const attrs = org.reduce((memo, a) => {
        memo[a.name] = a.values;
        return memo
    }, {});

    return (
        <TableRow>
            <TableCell width={3}>
                <AssignmentCheckbox
                    status={task.status}
                    checked={task.checked}
                    onClick={assignTask}
                    task={task}
                />
            </TableCell>
            <TableCell width={10}>
                <List.Header>
                    <b>{attrs.org_name}</b>
                </List.Header>
                <List.Content>
                    {task.reason.titleize()}
                </List.Content>
            </TableCell>
            <TableCell>
                {!!task.curated_by ? global.ORG_SOURCES[task.curated_by] : 'unassigned'}
            </TableCell>
            <TableCell>
                {task.status.titleize()}
            </TableCell>
            <TableCell>
                {attrs.curation_status ? attrs.curation_status : 'Unverified'}
            </TableCell>
            <TableCell width={3}>
                skipped: {task.punt_count}
            </TableCell>
        </TableRow>
    );
};

export default TaskItem;
