import React, { Component } from 'react';
import constants from "../../../../constants";
import _ from "lodash"
import Attribute from '../../../../model/Attribute'

class ClassificationEditor extends Component {

  handleCheckbox = (target) => {
    const attrExists = this.props.classifications[target.value];
    if (attrExists) {
      _.filter(this.props.attribute, { values: target.value }).forEach((a) => this.props.deleteRepeatedAttribute(a, this.props.org));
    } else {
      const newAttr = new Attribute({
        values: target.value
      },
        this.props.attribute.apiKey,
        this.props.attribute.type,
        this.props.org.id);
      this.props.addNewAttribute(newAttr);
    }
  }

  render() {    
    return (
      global.CLASSIFICATION_LIST.map(
        (el, i) => {
          const checked = this.props.classifications[el];
          return (
            <label key={`${i}-label`}>
              <input type="checkbox"
                key={`${i}-${el}`}
                name={el}
                value={el}
                defaultChecked={checked}
                onChange={(e) => { this.handleCheckbox(e.target) }}
                style={{ marginRight: '5px' }}
              />
              {el}
              <br />
            </label>
          )
        })
    );
  }
}

export default ClassificationEditor;