import React from 'react'
import { Link } from 'react-router-dom'
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { connect } from "react-redux"
import _ from 'lodash';
import constants from "../../constants";
import SearchResultsContainer from "./SearchResultsContainer";
import ClearIcon from '@material-ui/icons/Clear';
import LoadingIndicator from "./LoadingIndicator";
import Button from "@material-ui/core/Button";
import CreateIcon from '@material-ui/icons/Create';
import AuthContainer from "../AuthContainer";
import {useAuth0} from '../react-auth0-wrapper';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    link: {
        margin: theme.spacing(1),
        color: 'primary',
    },
}));

const CuratorMenu = ({ corralItems, handleSearch, searchResults, clearSearch, createOrg }) => {

    const {logout} = useAuth0();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [query, setQuery] = React.useState({});

    const openMenu = (e) => setAnchorEl(e.currentTarget);
    const closeMenu = (e) => setAnchorEl(null);

    const debounced = _.debounce(function () { handleSearch(query.value) }, 700);

    const onSearch = (e) => {
        setQuery(e.target);
        if(e.target && e.target.value !== "") {
            debounced(e);
        }
    };

    const onClear = () => {
        clearSearch();
        query.value = null;
        setQuery(null);
    };

    const linkStyles = {
        color: 'white',
        "&:hover": {
            color: 'blue',
            textDecoration: 'underline',
        }
    };

    const handleLogout = () => {
        logout();
    };

    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <IconButton onClick={openMenu} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="action-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={closeMenu}
                    >
                        <MenuItem>
                            <Link to="/">Worklist</Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to="/corral">Review List</Link>
                        </MenuItem>
                        <MenuItem onClick={handleLogout}>
                            <a>Logout</a>
                        </MenuItem>
                    </Menu>
                    <Typography variant="h6" className={classes.title}>
                        Company Curator
                    </Typography>
                    <AuthContainer requiredRoles={['Admin']}>
                        <Button color='default' variant='contained' onClick={createOrg}><CreateIcon />&nbsp;New Org</Button>
                    </AuthContainer>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                            onKeyDown={onSearch}
                        />

                        {searchResults &&
                            <IconButton onClick={onClear}>
                                <ClearIcon />
                            </IconButton>
                        }
                    </div>
                    <Link to="/corral" style={linkStyles}>
                        Review ({corralItems.length} items)
                    </Link>
                </Toolbar>
            </AppBar>
            <LoadingIndicator />
            <SearchResultsContainer />
        </>
    )
};

const stateToProps = (state, props) => {
    return {
        corralItems: state.corral.corralItems,
        searchResults: state.search.results
    }
};

const dispatchToProps = dispatch => ({
    handleSearch: query => dispatch(constants.actions.SEARCH.getObj({
        query
    })),
    clearSearch: () => dispatch(constants.actions.CLEAR_SEARCH.getObj()),
    createOrg: () => dispatch(constants.actions.CREATE_ORG.getObj()),
});

export default connect(stateToProps, dispatchToProps)(CuratorMenu);
