import React from "react"
import {Route} from 'react-router-dom'
import EditorView from "./editor/EditorView";
import Container from '@material-ui/core/Container';
import WorkList from "./worklist"
import TaskView from "./task/TaskView"
import CuratorMenu from "./CuratorMenu"
import CssBaseline from '@material-ui/core/CssBaseline';
import SnackBar from './SnackBar'
import Corral from './corral/Corral'
import ErrorBoundary from '../ErrorBoundary';
import AuthContainer from "../AuthContainer";
import Winner from "../Winner";
import {connect} from "react-redux";


const Curator = ({init}) => {
    return (
        <ErrorBoundary>
            <CssBaseline/>
            <CuratorMenu/>
            <br/>
            <Container maxWidth="xl">
                <ErrorBoundary>
                    <AuthContainer requiredRoles={['Admin', 'Curator']}>
                        <Route exact path="/" component={WorkList}/>
                        <Route matches path={"/org/edit/:id"} component={EditorView}/>
                        <Route matches path="/task/:id" component={TaskView}/>
                        <Route matches path="/corral" component={Corral}/>
                        <Route matches path={"/task/winner"} component={Winner}/>
                    </AuthContainer>
                </ErrorBoundary>
            </Container>
            <SnackBar/>
        </ErrorBoundary>
    );
};

const mapStateToProps = (state, props) => {
    return {
        ...state.curator
    }
};

export default connect(mapStateToProps)(Curator);
