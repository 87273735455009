import React from 'react';
import { connect } from "react-redux";
import _ from 'lodash';

const AuthContainer = ({auth, requiredRoles, requiredGroups, children}) => {
    const { authorized, roles, group } = auth;
    const matchedRoles = requiredRoles ? !_.isEmpty(_.intersection(requiredRoles, roles)) : true;
    const matchedGroups = requiredGroups ? _.findIndex(requiredGroups, group.name) > -1 : true;

    if(!authorized || !matchedRoles || !matchedGroups) {
        return null;
    }

    return children;
};

const mapStateToProps = (state, props) => {
    return {
        auth: state.authorization,
        requiredRoles: props.requiredRoles,
        requiredGroups: props.requiredGroups,
    }
};

const dispatchToProps = dispatch => ({});

export default connect(mapStateToProps, dispatchToProps)(AuthContainer);
