import constants from '../constants';
import isReducerType from "./isReducerType";

const user = (state = { list: [], loading: false, ...USER.user }, action) => {
  const isType = isReducerType(action);

  if (isType(constants.actions.SET_USER)) {
    return Object.assign({}, state, {
      name: action.user
    });
  }
  if (isType(constants.actions.ADD_SOURCE_TO_USER)) {
    return {
      ...state,
      sourceId: action.id
    }
  }
  if(isType(constants.actions.USER_AUTHENTICATED)) {
    const authUser = action.user;
    global.USER = {
      user: {
        groups: authUser['http://amplion.com/claims/groups'],
        roles: authUser['http://amplion.com/claims/roles'],
        ...authUser
      }
    };

    return {
      ...state,
      ...global.User,
    }
  }

  return state;
};

export default user;
