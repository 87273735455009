import { connect } from "react-redux";
import React from "react"
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ClearAllIcon from '@material-ui/icons/ClearAll';import clsx from 'clsx';
import _ from 'lodash'
import constants from '../../../constants'

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

function ClearButton({clearCorral, corralItems}) {
  const classes = useStyles();
  return (
    <div>
      <Button variant="contained" size="small" className={classes.button} onClick={clearCorral}>
        <ClearAllIcon className={clsx(classes.leftIcon, classes.iconSmall)} />
        Clear List
      </Button>
    </div>
  );
}

const mapStateToProps = (state) => ({ ...state.corral });
const mapDispatchToProps = (dispatch) => ({
    clearCorral: () => dispatch(constants.actions.CLEAR_CORRAL.getObj())
});

export default connect(mapStateToProps, mapDispatchToProps)(ClearButton)
