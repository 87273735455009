import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MergeButton from './MergeButton';
import RevertButton from './RevertButton';
import ClearButton from './ClearButton';
import WarningIcon from '@material-ui/icons/Warning';
import clsx from 'clsx';
import Typeography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },  
  warningIcon: {            
    color: 'red',    
  },    
}));

export default function CorralHeader({ submitMerge, revertMerge, corralItems, submitted, error }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs>
          <h1>Review</h1>
        </Grid>
        <Grid item xs={6}>
          {error &&
            <div style={{textAlign: "center"}}>
              <Typeography>
                <WarningIcon fontSize='large' className={clsx(classes.warningIcon)}  />
                &nbsp;Could not persist changes to the backend, please try again later.&nbsp;
                <WarningIcon fontSize='large' className={clsx(classes.warningIcon)}  />
              </Typeography>
            </div>
          }
        </Grid>
        <Grid item xs>
          {!submitted &&
            <MergeButton onClick={submitMerge} corralItems={corralItems} />
          }
          {submitted &&
            <RevertButton onClick={revertMerge} corralItems={corralItems} />
          }
          <ClearButton />  
        </Grid>
      </Grid>
    </div>
  );
}