import {all, takeLatest, call, delay} from 'redux-saga/effects';
import constants from '../constants';
import {Api} from '../services/api';
import {getAllOrgData} from '../client/orgs';
import putAction from "./putAction";
import _ from 'lodash';

export default function* () {
    yield all([
        takeLatest(constants.actions.SEARCH.type, search)
    ])
}

function* search(action) {
    if (_.isEmpty(action.query)) return;
    yield putAction(constants.actions.SEARCH_PENDING);
    yield delay(500);
    const {data, error} = yield call(
        Api.post,
        `/api/v1/org/search`,
        {
            search_text: action.query
        }
    );

    if (error) {
        yield putAction(constants.actions.SHOW_SNACKBAR,{
            variant: 'error',
            message: error
        })
    } else {
        yield putAction(constants.actions.SEARCH_SUCCESS,{ results: data });
    }
}

function* fetchOrg(action) {
    try {
        return yield call(
            getAllOrgData,
            action.orgId,
            {limit: 0}
        );
    } catch (error) {
        console.error(error);
    }
}


