import org from "./org";
import curationList from "./curationList";
import user from "./user";
import spinner from "./spinner";
import requests from './requests'
import alerts from './alerts'
import { task } from "./task"
import curator from "./curator"
import editor from "./editor"
import snackBar from "./snackBar"
import search from "./search"
import corral from "./corral"
import address from './address'
import errorBoundary from './errorBoundary'
import authorization from "./authorization";
import groups from "./ groups";

export default {
    org,
    curationList,
    user,
    spinner,
    requests,
    alerts,
    task,
    curator,
    editor,
    snackBar,
    search,
    corral,
    address,
    errorBoundary,
    authorization,
    groups,
};
