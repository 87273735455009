import constants from '../constants';
import isReducerType from "./isReducerType";

const org = (state = {}, action) => {
  const isType = isReducerType(action);

  if (isType(constants.actions.FETCH_ORG_BEGIN)) {
    return {
      ...state,
      loading: true,
      error: null
    }
  }

  if (isType(constants.actions.FETCH_ORG_ADDRESSES_SUCCESS)) {
    let addresses = action.payload;

    return Object.assign({}, state, {
      addresses:addresses,
    });
  }

  return {
    ...state,
    loading: false,
    error: null
  };
};

export default org;
