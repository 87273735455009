import Button from '@material-ui/core/Button';
import { connect } from "react-redux";
import constants from "../../constants";
import React, {useState} from "react";
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from "@material-ui/core/Typography";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AuthContainer from "../AuthContainer";
import { makeStyles,useTheme } from '@material-ui/core/styles';

const DeleteButton = ({ org, deleteOrg }) => {
    const theme = useTheme();
    const useStyles = makeStyles({
        Button: {
            color: theme.palette.error.main,
        }
    });

    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const onClickDelete = (e) => {
        setOpen(true);
    };

    const handleClose = (e) => {
        setOpen(false);
    };

    const handleConfirm = (e) => {
        deleteOrg(org);
        setOpen(false);
    };

    return (
        <AuthContainer requiredRoles={['Admin']}>
            <Button
                className={classes.Button}
                onClick={onClickDelete}
                aria-label="mark for review">
                <DeleteIcon />
                <Typography>
                    Delete Org
                </Typography>
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Are you sure you wish to delete this Org?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You will not be able to undo this action.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm} className={classes.Button} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </AuthContainer>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        org: ownProps.org,
    }
};

const dispatchToProps = (dispatch) => ({
    deleteOrg: (org) => {
        dispatch(constants.actions.DELETE_ORG.getObj({
            org
        }));
    }
});



export default connect(mapStateToProps, dispatchToProps)(DeleteButton);
