import React from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import AddressRow from "./AddressRow";
import {formatAddresses} from "../../../../helpers/addressHelper";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

class AddressList extends React.Component {

    handlePromoteAddress = (address) => {
        return () => {
            this.props.promoteAddress(address, this.props.org);
        }
    };

    handleDemoteAddress = (address) => {
        return () => {
            this.props.demoteAddress(address, this.props.org);
        }
    };

    render() {
        const addresses = formatAddresses(this.props.attribute)[this.props.options.addressType] || [];
        const isLegit = (address) => address.confidence >= 0.95;
        return (
            <Table size="small">
                <colgroup>
                    <col width='10%'/>
                    <col width='10%'/>
                    <col width='5%'/>
                    <col width='5%'/>
                    <col width='10%'/>
                    <col width='10%'/>
                    <col width='5%'/>
                    <col width='15%'/>
                    <col width='20'/>
                </colgroup>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Street
                        </TableCell>
                        <TableCell>
                            City
                        </TableCell>
                        <TableCell>
                            State/Province
                        </TableCell>
                        <TableCell>
                            Country
                        </TableCell>
                        <TableCell>
                            Postal Code
                        </TableCell>
                        <TableCell>
                            Phone
                        </TableCell>
                        <TableCell>
                            Confidence
                        </TableCell>
                        <TableCell>
                            Source
                        </TableCell>
                        <TableCell>

                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {addresses.map((a) => {
                    return (
                    <AddressRow
                        key={`${a.id}-card-editor`}
                        onClickPromote={this.handlePromoteAddress(a)}
                        onClickDemote={this.handleDemoteAddress(a)}
                        onClickEdit={() => this.props.onEdit(a)}
                        isSelected={isLegit(a)}
                        address={a}
                    />)
                })}
                </TableBody>
            </Table>
        );
    }
}

export default AddressList;
