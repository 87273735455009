import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import AddressDisplay from "../editor/AddressEditor/AddressDisplay";
import {Button} from "semantic-ui-react";
import React from "react";
import {formatAddresses} from "../../../helpers/addressHelper";
import TableBody from "@material-ui/core/TableBody";


const useStyles = makeStyles({
   selectable:  {
       '&:hover': {
           backgroundColor: '#f0f0f0',
        }
   }
});

export const AddressCards = ({addresses, onSelect, editEnabled}) => {

    const handleClick = (type) => () => {
        onSelect(addresses, {addressType: type})
    };

    const addressesByType = formatAddresses(addresses);
    const classes = useStyles();
    const rowClass = editEnabled ? classes.selectable : {};
    const cursor = editEnabled ? 'pointer' : '';

    return (
        <>
            {Object.keys(addressesByType).length > 0 && Object.keys(addressesByType).map((addressType) => (
                <div key={addressType}>
                    <Typography>
                        {addressType}{`(${addressesByType[addressType].length})`}
                    </Typography>
                    <Paper onClick={handleClick(addressType)}
                           style={{cursor}}
                           key={`${addressType}-section`}>
                        <Table size='small'>
                            <colgroup>
                                <col width='30%'/>
                                <col width='10%'/>
                                <col width='5%'/>
                                <col width='5%'/>
                                <col width='10%'/>
                                <col width='10%'/>
                                <col width='5%'/>
                                <col width='15%'/>
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Street
                                    </TableCell>
                                    <TableCell>
                                        City
                                    </TableCell>
                                    <TableCell>
                                        State/Province
                                    </TableCell>
                                    <TableCell>
                                        Country
                                    </TableCell>
                                    <TableCell>
                                        Postal Code
                                    </TableCell>
                                    <TableCell>
                                        Phone
                                    </TableCell>
                                    <TableCell>
                                        Confidence
                                    </TableCell>
                                    <TableCell>
                                        Source
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {addressesByType[addressType].filter(a => a.confidence > 0).map(address => (
                                <TableRow className={rowClass} key={address.id}>
                                    <AddressDisplay
                                        address={address}
                                        key={`${address.addr_street_address}-card`}
                                    />
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </Paper>
                    <br/>
                </div>
            ))}
            {Object.keys(addressesByType).length === 0 && (
                <TableRow onClick={handleClick("Uncategorized")} style={{cursor: 'pointer'}}>
                    <TableCell>
                        Addresses (0)
                    </TableCell>
                    <TableCell>
                        ---
                    </TableCell>
                    <TableCell>
                        <Button color="green" size="small" floated="right" style={{height: '33px'}}>
                            ADD
                        </Button>
                    </TableCell>
                </TableRow>
            )}
        </>)
};
