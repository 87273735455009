import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from './sagas';
import reducers from "./reducers";
import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'

export const history = createBrowserHistory()

export default function configureStore(initial_state) {
  const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true // Change this to true to use the Trace tab in Redux DevTools. Makes things slow
      }) : compose;
  const reducer = combineReducers({
    ...reducers,
    router: connectRouter(history),
  });
  const sagaMiddleware = createSagaMiddleware({
    onError: (error, {sagaStack}) => {
      console.error(error, sagaStack);
    }
  });
  const store = createStore(
    reducer,
    initial_state,
    composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware)),
  );

  sagaMiddleware.run(rootSaga);

  return store;
}
