import constants from '../constants';
import _ from 'lodash'
import isReducerType from "./isReducerType";

const getClassifications = (attribute) => {
  let classifications = {}

  global.CLASSIFICATION_LIST.reduce((a, c) => {
    a[c] = _.some(attribute, (r) => r.confidence > 0 && c === r.values);
    return a
  }, classifications)

  return classifications
}

const editor = (state = {loading: false}, action) => {
  const isType = isReducerType(action);
  if (isType(constants.actions.CLEAR_EDITOR)) {
    return {
      org: state.org,
      loading: false
    }
  }

  if (isType(constants.actions.FETCH_ORG_ADDRESSES_SUCCESS)) {
    let addresses = action.payload;

    return Object.assign({}, state, {
      ...state,
      addresses: addresses
    });
  }

  if (isType(constants.actions.PROMOTE_ATTRIBUTE)) {
    const attr = action.attribute;
    const oldAttrs = state.attribute;
    oldAttrs.promote(attr);
    return Object.assign({}, state, {
      ...state,
      tainted: true,
      attribute: oldAttrs,
    });
  }

  if (isType(constants.actions.EDIT_ATTRIBUTE)) {
    const {attribute, addresses, options} = action;
    const header = global.FRIENDLIES[action.attribute.apiKey];

    return {
      ...state,
      visible: true,
      attribute,
      addresses,
      header,
      classifications: attribute.apiKey === 'org_classification' ? getClassifications(attribute) : {},
      options
    }
  }

  if (isType(constants.actions.UPDATE_EDITOR_ATTRIBUTE)) {
    const {attribute} = action;
    return {
      ...state,
      create: false,
      tainted: true,
      attribute: attribute,
      classifications: attribute && attribute.apiKey === 'org_classification' ? getClassifications(attribute) : {},
    }
  }

  if (isType(constants.actions.FETCH_ORG_PENDING)) {
    return {
      loading: true
    }
  }

  if (isType(constants.actions.FETCH_ORG_ERROR)) {
    return {
      ...state,
      loading: false
    }
  }

  if (isType(constants.actions.SET_ORG)) {
    return {
      ...state,
      loading: false,
      org: action.org,
    }
  }

  if (isType(constants.actions.FETCH_RELATIONS_DONE)) {
    return {
      ...state,

    }
  }

  return state;
};

export default editor;
