import {all, call, put, select, takeEvery, takeLatest, delay} from 'redux-saga/effects'
import constants from '../constants';
import {Api} from '../services/api';
import _ from "lodash";
import putAction from "./putAction";

export default function* () {
    yield all([
        takeEvery(constants.actions.TASK_STATUS_UPDATED.type, setTaskStatus),
        takeLatest(constants.actions.FETCH_TASKS.type, fetchTasks),
        takeEvery(constants.actions.FETCH_TASK.type, fetchTask),
        takeEvery(constants.actions.ASSIGN_TASK.type, assignTask),
        takeEvery(constants.actions.ASSIGN_TASK_BULK.type, assignTaskBulk),
    ])
}

function* assignTaskBulk(action) {
    const {tasks, user, group} = action;
    if(_.isEmpty(tasks)){
        return;
    }
    yield putAction(constants.actions.FETCH_STARTED);
    const results = yield all(
        tasks.map(task => call(
            Api.post,
            `/api/v1/task/${task.id}/curatedby/${user.sourceId}`)
        )
    );
    const errors = _.filter(results, r => !!r.error) || [];

    if(errors.length === 0){
        yield putAction(constants.actions.FETCH_TASKS, {group});
    }else{
        yield putAction(constants.actions.ASSIGN_TASK_ERROR, { errors });
    }
    yield putAction(constants.actions.FETCH_ENDED);
}

function* assignTask(action) {
    const {task, user, group} = action;
    const {data, error} = yield call(
        Api.post,
        `/api/v1/task/${task.id}/curatedby/${user.sourceId}`,
        {}
    );
    if (data) {
        return yield putAction(constants.actions.FETCH_TASKS, {group});
    }
    if (error) {
        return yield putAction(constants.actions.TASK_STATUS_UPDATE_FAIL, { error });
    }
}

function* setTaskStatus(action) {
    const {task, status} = action;
    const {data, error} = yield call(
        Api.post,
        `/api/v1/task/${task.id}/${status}`,
        {}
    );
    if (data) {
        yield putAction(constants.actions.TASK_STATUS_UPDATE_SUCCESS, { status });
    }
    if (error) {
        yield putAction(constants.actions.TASK_STATUS_UPDATE_FAIL, { error });
    }
}

function* fetchTasks(action) {
    const {group} = _.defaults(action, {group: global.USER.group});
    yield putAction(constants.actions.FETCH_TASKS_BEGIN);
    const [
            {data: groupData, error: groupError},
            {data: userData, error: userError}
        ] = yield all([
            call(Api.get, `/api/v1/tasks/group/${group.id}`),
            call(Api.get, `/api/v1/tasks/curatedby/${global.USER.sourceId}`)
        ]
    );

    const error = groupError || userError;

    if (error) {
        yield putAction(constants.actions.FETCH_TASKS_ERROR,{error});
    } else {
        const group = !!groupData ?  _.orderBy(groupData.map(d => ({...d, checked: d.curated_by === global.USER.sourceId })), ['id'], ['desc']) : [];
        const user = !!userData ? _.map(_.orderBy(userData, ['id'], ['desc']), t => ({...t, checked: true})) : [];

        yield putAction(constants.actions.FETCH_TASKS_SUCCESS,{
            payload: {
                group,
                user,
            }
        })
    }
}

function* fetchTask(action) {
    const {taskId} = action;
    yield putAction(constants.actions.TASK_LOADING);
    const {data, error} = yield call(
        Api.get,
        `/api/v1/task/${taskId}`
    );
    yield putAction(constants.actions.SET_CURRENT_TASK,{ task: data });
}

