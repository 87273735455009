import {TableCell, TableRow} from '@material-ui/core'
import React from "react";
import AttributeDisplay from "./AttributeDisplay";
import EditorButtonGroup from "./controls/EditorButtonGroup";

const AttributeFormCell = ({isSelected, attribute, setCurrentlyEditing, onDeleteRepeated, onPromote, onDemote, onDelete, DisplayComponent}) => {

    const Displayer = DisplayComponent || AttributeDisplay;

    return <TableRow>
        <TableCell>
            <div style={{padding: '10px'}}>
                <Displayer attr={attribute}/>
                <EditorButtonGroup
                    onDemote={onDemote}
                    setCurrentlyEditing={setCurrentlyEditing}
                    onDeleteRepeated={onDeleteRepeated}
                    onPromote={onPromote}
                    onDelete={onDelete}
                    attribute={attribute}
                    isSelected={isSelected}
                />
            </div>
        </TableCell>
    </TableRow>
};

export default AttributeFormCell;
