import React from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import { Link } from 'react-router-dom'
import {withRouter} from 'react-router-dom';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError || !_.isEmpty(this.props.error)) {
            // You can render any custom fallback UI

            return (
                <div>
                    <h1>Something went wrong.</h1>
                    <Link to={this.props.location} onClick={() => window.location = "/"}>Reload Application</Link>
                </div>
            );
        }

        return this.props.children;
    }
}

const mapStateToProps = (state, props) => {
    return {
        error: state.errorBoundary
    }
};

const dispatchToProps = dispatch => (
    {
    }
);

export default connect(mapStateToProps, dispatchToProps)(withRouter(props => <ErrorBoundary {...props} />));
