import {all, call, select, takeLeading} from 'redux-saga/effects'
import constants from '../constants'
import {Api} from '../services/api'
import _ from "lodash"
import {getGroups} from "../client/orgs";
import putAction from "./putAction";

export default function* sourceSagas() {
    yield all([
        takeLeading(constants.actions.APP_INITIALIZE_START.type, init),
    ]);
}

const getGroupList = state => state.groups.list;

function* fetchSources() {
    if (_.isEmpty(global.ORG_SOURCES)) {
        const {data, error} = yield call(Api.get, '/api/v1/sources');
        global.ORG_SOURCES = _.reduce(data, (memo, s) => {
            memo[s.id] = s.name;
            return memo;
        }, {});
        if (error) {
            yield putAction(constants.actions.FATAL_ERROR,{error});
        }
        yield putAction(constants.actions.ORG_SOURCES_LOADED)
    }
}

function* fetchGroups() {
    const groups = yield call(getGroups);
    yield putAction(constants.actions.FETCH_GROUPS_SUCCESS,{groups});
}

function* findCurrentUserSource() {
    const user = global.USER.user;
    const sourceId = parseInt(_.invert(global.ORG_SOURCES)[user.email], 10);

    if (!sourceId) {
        yield putAction(constants.actions.USER_NOT_FOUND_IN_SOURCES,{user})
    } else {
        global.USER = {
            user,
            sourceId
        };
        yield putAction(constants.actions.ADD_SOURCE_TO_USER,{id: sourceId});
        yield putAction(constants.actions.USER_LOADED, {user: global.USER});
    }
}

function* loadFriendlies(action) {
    yield global.FRIENDLIES = {
        "founded_on": "Date Founded",
        "description": "Address Type",
        "addr_city": "City",
        "addr_country": "Country",
        "addr_phone": "Phone",
        "addr_postal_code": "Postal Code",
        "addr_state": "State/Province",
        "addr_street_address": "Street Address",
        "org_about": "About",
        "org_cb_url": "Crunchbase URL",
        "org_classification": "Classification",
        "org_description": "Description",
        "org_domain": "Website",
        "org_employee_count": "Headcount",
        "org_funding_closed_on": "Funding Closed On",
        "org_jobs": "Open Jobs",
        "org_last_funding": "Last Funding",
        "org_linkedin_url": "LinkedIn URL",
        "org_logo_url": "Logo URL",
        "org_name": "Name",
        "org_status": "Operating Status",
        "org_synonym": "Also Known As",
        "stock": "Stock"
    };

    yield putAction(constants.actions.FRIENDLIES_LOADED);
}

function* loadClassificationList(action) {
    const {data, error} = yield call(
        Api.get,
        `/api/v1/sectors`
    );

    yield global.CLASSIFICATION_LIST = data;
    yield putAction(constants.actions.CLASSIFICATIONS_LOADED);
}

function* isUserGroupMember() {
    const groups = yield select(getGroupList);
    const matchedGroups = _.intersection(_.intersection(global.USER.user.groups, groups.map(g => g.name)));
    if (_.isEmpty(matchedGroups)) {
        return yield putAction(constants.actions.UNAUTHORIZED,{ error: 'RBAC failed'});
    }

    global.USER.group = _.find(groups, {name: global.USER.user.groups[0]});
    yield putAction(constants.actions.AUTHORIZATION_SUCCESS,{group: global.USER.group, roles: global.USER.user.roles});
}

function* init(action) {
    if(!!global.USER.group && global.ORG_SOURCES){
        yield putAction(constants.actions.APP_INITIALIZE_DONE);
    }
    yield putAction(constants.actions.APP_INITIALIZE_PENDING);
    const {user} = action;
    global.USER = {
        user: {
            groups: user['http://amplion.com/claims/groups'],
            roles: user['http://amplion.com/claims/roles'],
            ...user
        }
    };
    yield all([
        call(fetchSources),
        call(fetchGroups),
        call(loadFriendlies),
        call(loadClassificationList),
    ]);

    yield all([
        call(findCurrentUserSource),
        call(isUserGroupMember, user['http://amplion.com/claims/groups'])
    ]);
    yield putAction(constants.actions.APP_INITIALIZE_DONE);
}


