import React from "react"
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import clsx from 'clsx';
import _ from 'lodash'

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

export default function MergeButton({onClick, corralItems}) {  
  const classes = useStyles();
  const disabled = !_.some(corralItems, 'parent') || !_.every(corralItems, 'relation');
  
  return (
    <div>
      <Button disabled={disabled} variant="contained" size="small" className={classes.button} onClick={() => onClick(corralItems)}>
        <SaveIcon className={clsx(classes.leftIcon, classes.iconSmall)} />
        Save
      </Button>
    </div>
  );
}