import constants from '../constants';
import isReducerType from "./isReducerType";

const snackBar = (state = { variant: 'none' }, action) => {
    const isType = isReducerType(action);

    if (isType(constants.actions.SHOW_SNACKBAR)) {
        const {variant, message } = action;
        return {
            ...state,
            variant,
            message,
        }
    }

    if (isType(constants.actions.DELETE_ORG_SUCCEEDED)) {
        return {
            ...state,
            variant: 'success',
            message: 'Org Deleted',
        }
    }

    if (isType(constants.actions.TASK_STATUS_UPDATE_SUCCESS)) {
        const {status} = action;
        const statusToFriendly = {
            to_do: 'To Do',
            punted: 'Skipped',
            in_progress: 'In Progress',
            done: 'Done',
        };

        return {
            ...state,
            variant: 'success',
            message: `Task set to ${statusToFriendly[status]}`,
        }
    }

    if (isType(constants.actions.TASK_STATUS_UPDATE_FAIL)) {
        const {error} = action;

        return {
            ...state,
            variant: 'error',
            message: `ERROR: Could not update task. ${error}`,
        }
    }

    if (isType(constants.actions.HIDE_SNACKBAR)) {
        return {
            variant: 'none',
            message: undefined
        }
    }

    return state;
};

export default snackBar;
