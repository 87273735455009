import constants from '../constants';
import isReducerType from "./isReducerType";

const DEFAULT = {
    doneDisabled : false,
    currentStep: "EDIT",
    loading: false
};

const task = (state = DEFAULT, action) => {
    const isType = isReducerType(action);

    if (isType(constants.actions.TASK_LOADING)) {
        return {
            ...state,
            loading: true
        }
    }

    if (isType(constants.actions.SET_CURRENT_TASK)) {
        return {
            ...state,
            ...action.task,
            loading: false
        }
    }

    if (isType(constants.actions.ORG_MERGE_SUCCESS)) {
        return {
            ...state,
            currentStep: "VERIFY"
        }
    }

    if (isType(constants.actions.ORG_REVERT_SUCCESS)) {
        return {
            ...state,
            doneDisabled: true,
            currentStep: "EDIT"
        }
    }

    return state;
};

export { task };
