import React, { useState } from 'react';
import {Label} from "semantic-ui-react";

function useUrlValidation(initialUrl) {
    const [url, setUrl] = useState(initialUrl);

    const validateUrl = () => {
        if(!url) return true;
        const pattern = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
        return pattern.test(url);
    };

    const isValid = validateUrl();

    const ValidationAlert = () => {
        return (
            <>
                {!isValid &&
                <Label pointing='below' basic color="red">
                    Enter a valid Website with http:// or https://
                </Label>}
            </>
        );
    };

    return [url, setUrl, isValid, ValidationAlert];

}

export default useUrlValidation;
