import React from "react"
import _ from "lodash";
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import TableRow from '@material-ui/core/TableRow';
import MergesTable from "./MergesTable";
import Typography from "@material-ui/core/Typography";
import {AddressCards} from "./AddressCards";
import {makeStyles} from "@material-ui/core/styles";
import Chip from '@material-ui/core/Chip';
import moment from 'moment';

const displayAttrs = [
    "org_logo_url",
    "org_name",
    "curation_status",
    "org_synonym",
    "org_domain",
    "org_linkedin_url",
    "org_employee_count",
    "org_about",
    "org_status",
    "org_classification",
    "stock",
    "founded_on",
];

const useStyles = makeStyles({
    selectable: {
        '&:hover': {
            backgroundColor: '#f0f0f0',
        }
    }
});

const AttributeTable = ({org, onSelect}) => {
    const attrs = org.attributes;
    const lastUpdated = _.orderBy(_.compact(displayAttrs.map((k) => attrs[k].top)), ['updated_at'], ['desc'])[0];

    if (attrs) {
        return (
            <div style={{flexGrow: 1}}>
                {lastUpdated &&
                <Grid container spacing={3}>
                    <Grid item>
                        <Typography>
                            <b>{lastUpdated.name.titleize()}</b> updated on {moment(lastUpdated.updated_at).format('ll')} by <b>{global.ORG_SOURCES[lastUpdated.source_id]}</b>.
                        </Typography><br />
                    </Grid>
                </Grid>
                }
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={8}>
                        <h4>Attributes</h4>
                        <Paper>
                            <Table size='small'>
                                <AttrTableBody
                                    org={org}
                                    onSelect={onSelect}
                                />
                            </Table>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <h4>Merges</h4>
                        <Paper>
                            {!_.isEmpty(org.merges) &&
                                <MergesTable children={org.merges} editEnabled={org.active}/>
                            }
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <h4>Addresses</h4>
                        <AddressCards
                            key={`address`}
                            addresses={attrs['address']}
                            onSelect={onSelect}
                            editEnabled={org.active}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
    return (<></>);
}

const AttrTableBody = ({org, onSelect}) => {
    const attrs = org.attributes;
    return (
        <TableBody>
            {displayAttrs.map((k, i) => {
                if (k === 'stock') {
                    return (<StockInfoRow key={`${k}-${i}`} stockInfo={attrs[k]} onSelect={onSelect} editEnabled={org.active}/>);
                }
                if (k === 'org_logo_url') {
                    return (<ImageRow key={`${k}-${i}`} image={attrs[k]} onSelect={onSelect} editEnabled={org.active} />);
                }
                return (<AttrRow key={`${k}-${i}`} attrs={attrs[k]} onSelect={onSelect} editEnabled={org.active}/>);
            })}
        </TableBody>
    );
};

const Row = ({display, onSelect, editEnabled, children}) => {
    const classes = useStyles();
    const rowClass = editEnabled ? classes.selectable : {};
    const cursor = editEnabled ? 'pointer' : '';

    return (
        <TableRow onClick={onSelect} className={rowClass} style={{cursor}}>
            <TableCell>
                <Typography>
                    {display}
                </Typography>
            </TableCell>
            <TableCell style={{paddingTop: '5px'}}>
                <Typography component={"div"}>
                    {children}
                </Typography>
            </TableCell>
        </TableRow>
    );
};

const ImageRow = ({image, onSelect, editEnabled}) => {
    const display = 'Logo';
    const handleClick = () => {
        onSelect(image, display)
    };
    return (
        <Row display={display} editEnabled={editEnabled} onSelect={handleClick}>
            {image.top ?
                <img style={{maxHeight: '150px'}} src={image.top.values} />
            :
                '---'
            }
        </Row>
    )
};

const StockInfoRow = ({stockInfo, onSelect, editEnabled}) => {
    const display = stockInfo.display();
    const values = stockInfo.top ? stockInfo.top.values : '---';
    const symbol = (values && values.symbol) ? values.symbol : '---';
    const exchange = (values && values.exchange) ? values.exchange : '---';
    const displayValue = `${symbol} (${exchange})`;

    const handleClick = () => {
        onSelect(stockInfo, display)
    };

    return (
        <Row display={display} onSelect={handleClick} editEnabled={editEnabled}>
            <Typography component={"span"}>{displayValue}</Typography>
        </Row>
    );
};

const AttrRow = ({attrs, onSelect, editEnabled}) => {
    let displayValue;
    const display = attrs.display();

    if (attrs.top && attrs.type.repeated) {
        const sorted = attrs.sort(function (a, b) {
            let valueA = a ? a.values.toLowerCase() : "";
            let valueB = b ? b.values.toLowerCase() : "";
            if (valueA < valueB) {
                return -1;
            }
            if (valueA > valueB) {
                return 1;
            }
            return 0;
        }).filter((a) => a.confidence > 0);
        displayValue = _.uniqBy(sorted, 'values').map((el, i) => (<Chip key={el.values} style={{margin: '2px'}} color='primary' label={el.values} />));
    } else {
        displayValue = attrs.top ? attrs.top.values : '---';
    }

    const handleClick = () => {
        onSelect(attrs, display)
    };

    if (attrs.type.formatter) {
        displayValue = attrs.type.formatter(attrs);
    }

    return (
        <Row display={display} onSelect={handleClick} editEnabled={editEnabled}>
            <Typography component={"div"}>
                {displayValue}
            </Typography>
        </Row>
    );
};

export default AttributeTable;
