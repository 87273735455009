import React from "react";
import {Typography} from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell';

export const AddressTemplate = ({address, sourceName}) => {
    const values = address.values;
    return (
        <>
            <TableCell>
                <Typography>
                    {values.addr_street_address}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography>
                    {values.addr_city}
                </Typography>
            </TableCell>
            <TableCell>
                {values.addr_state}
            </TableCell>
            <TableCell>
                <Typography>
                    {values.addr_country}
                </Typography>
            </TableCell>
            <TableCell>
                {values.addr_postal_code}
            </TableCell>
            <TableCell>
                <Typography>
                    {values.addr_phone}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography>
                    {address.confidence}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography>
                    {sourceName}
                </Typography>
            </TableCell>
        </>
    )
};

const AddressDisplay = ({address}) => {
    const sources = global.ORG_SOURCES;
    const sourceName = sources[address.source_id] ? sources[address.source_id] : "You";
    return (<AddressTemplate address={address} sourceName={sourceName}/>);
};

export default AddressDisplay;
