import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import React from "react";
import { dataTypes } from '../../../../model/attributeTypes'

const EditorButtonGroup = ({attribute, setCurrentlyEditing, onPromote, onDelete, onDeleteRepeated, onDemote, isSelected}) => {
    const itemStyle = {
        cursor: 'pointer',
        paddingRight: '15px',
    };

    const isEditable = attribute.source_id === global.USER.sourceId && attribute.type.dataType !== dataTypes.ENUM && attribute.type.dataType !== dataTypes.STOCK;
    const isCopyable = attribute.source_id !== global.USER.sourceId && attribute.type.dataType !== dataTypes.ENUM && attribute.type.dataType !== dataTypes.STOCK;

    const isDeleted = attribute.confidence <= 0;

    return (
        <div style={{float: 'right', marginTop: '5px'}}>
            <ButtonGroup>
                <Button
                    style={itemStyle}
                    color='primary'
                    onClick={onPromote}
                    disabled={isSelected}>
                    Promote
                </Button>
                {isEditable && !attribute.type.repeated &&
                <Button
                    style={itemStyle}
                    color='primary'
                    onClick={onDelete}
                    disabled={isDeleted}>
                    Delete
                </Button>
                }
                {isEditable && attribute.type.repeated &&
                <Button
                    style={itemStyle}
                    color='primary'
                    onClick={onDeleteRepeated}
                    disabled={isDeleted}>
                    Delete
                </Button>
                }
                {!isEditable &&
                <Button
                    name="demote"
                    style={itemStyle}
                    color='primary'
                    onClick={onDemote}
                    disabled={isDeleted}>
                    Demote
                </Button>
                }
                {isEditable &&
                <Button
                    name="edit"
                    style={itemStyle}
                    color='primary'
                    onClick={() => setCurrentlyEditing(attribute)}
                >Edit</Button>
                }
                {isCopyable &&
                <Button
                    name="copy"
                    color='primary'
                    style={itemStyle}
                    onClick={() => setCurrentlyEditing(attribute)}
                >Copy</Button>
                }
            </ButtonGroup>
        </div>
    )
};

export default EditorButtonGroup;
