import React, { useState } from 'react';
import Attribute from "../../../../model/Attribute";

function useCurrentlyEditing(org, originalAttribute) {
    const createBoundAttribute = (attribute, values) => new Attribute({
        ...attribute,
        values
    }, attribute.apiKey, attribute.type, org.id);

    const [currentlyEditing, setCurrentlyEditing] = useState(createBoundAttribute(originalAttribute, ''));

    const onChange = (e) => {
        const value = e.target.value;
        setCurrentlyEditing(createBoundAttribute(currentlyEditing, value));
    };

    return [currentlyEditing, setCurrentlyEditing, onChange];
}

export default useCurrentlyEditing;
