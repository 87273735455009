import {all, put, take, takeEvery} from 'redux-saga/effects'
import constants from '../constants'
import _ from "lodash"
import putAction from "./putAction";

export default function* () {
    yield all([
        takeEvery("@@router/LOCATION_CHANGE", onTaskRoute),
        takeEvery("@@router/LOCATION_CHANGE", onOrgRoute),
    ])
}

const waitForInit = function* () {
    if (_.isEmpty(global.USER.user)) {
        yield take(constants.actions.APP_INITIALIZE_DONE.type);
    }
};

function* onOrgRoute(action) {
    const EDIT = "edit";
    yield waitForInit();
    const pathParts = action.payload.location.pathname.split("/");

    if (pathParts.includes(EDIT)) {
        yield putAction(constants.actions.FETCH_ALL_ORG_DATA,{ orgId: pathParts[pathParts.indexOf(EDIT) + 1]})
    }
}

function* onTaskRoute(action) {
    const TASK = "task";
    yield waitForInit();
    const pathParts = action.payload.location.pathname.split("/");

    if (pathParts.includes(TASK)) {
        const taskId = pathParts[pathParts.indexOf(TASK) + 1];
        yield putAction(constants.actions.FETCH_TASK,{ taskId });
    }
}

