import React from "react";
import Table from '@material-ui/core/Table';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import {makeStyles} from '@material-ui/core/styles';
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TaskItem from "./TaskItem";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

const getActiveTasks = (pageNumber, list, tasksPerPage, status, group) => {
    const begin = tasksPerPage * (pageNumber);
    const end = begin + tasksPerPage;
    const result = _.filter(list, t => t.assigned_to === group.id);
    return _.filter(list, t => t.status === status && t.assigned_to === group.id).slice(begin, end);
};

const useStyles1 = makeStyles(theme => ({
    root: {
        flexShrink: 0,
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const {count, page, rowsPerPage, onChangePage} = props;

    const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                <FirstPageIcon/>
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                <KeyboardArrowLeft/>
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                <KeyboardArrowRight/>
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                <LastPageIcon/>
            </IconButton>
        </div>
    );
}

const Paginator = ({tasks, rowsPerPage, page, onChangePage, handleChangeRowsPerPage}) => {
    return (
        <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={tasks.list.length}
            rowsPerPage={rowsPerPage}
            colSpan={3}
            page={page}
            SelectProps={{
                inputProps: {'aria-label': 'rows per page'},
                native: true,
            }}
            onChangePage={onChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
        />
    )
};

const TaskTable = ({setActivePage, tasks, status, fetchOrg, activePages, assignTask, assignTaskBulk, group}) => {
    const [page, setPage] = React.useState(activePages[status]);
    const onChangePage = (e, o) => {
        setPage(o);
        setActivePage(o, status);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const list = tasks.list;
    const activeTasks = getActiveTasks(page, list, rowsPerPage, status, group);
    const orgs = tasks.orgs;
    const isChecked = _.isEmpty(activeTasks) ? false : _.every(activeTasks, t => t.checked);

    const handleClick = () => {
        const user = isChecked ? {sourceId: 0} : global.USER;
        assignTaskBulk({tasks: activeTasks, user, group});
    };

    const handleAssignTask = (o) => {
      assignTask({ ...o, group });
    };

    return (
        <Paper style={{padding: '15px'}}>
            <Grid container direction='row' alignItems='center' spacing={6}>
                <Grid item>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isChecked}
                                icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
                                checkedIcon={<CheckBoxIcon fontSize="small"/>}
                                onClick={handleClick}
                                color='secondary'
                                disabled={tasks.list.length < 1}
                            />}
                        label={isChecked ? 'Unselect All' : 'Select All'}
                    />
                </Grid>
            </Grid>
                <Table size='small'>
                    <colgroup>
                        <col width='5%'/>
                        <col width='25%'/>
                        <col width='15%'/>
                        <col width='15%'/>
                        <col width='20%'/>
                        <col width='20%'/>
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Action
                            </TableCell>
                            <TableCell>
                                Description
                            </TableCell>
                            <TableCell>
                                Assignee
                            </TableCell>
                            <TableCell>
                                Task Status
                            </TableCell>
                            <TableCell>
                                Org Status
                            </TableCell>
                            <TableCell>
                                Info
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {activeTasks.map((task) => <TaskItem
                            key={task.id}
                            fetchOrg={fetchOrg}
                            task={task}
                            assignTask={handleAssignTask}
                            org={orgs[task.org_id]}/>)}
                    </TableBody>
                </Table>
                <Paginator
                    tasks={tasks}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={onChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />

        </Paper>
    );
};

export default TaskTable;
