import constants from '../constants';
import _ from 'lodash'
import isReducerType from "./isReducerType";

const errorBoundary = (state = {}, action) => {
    const isType = isReducerType(action);
    if (isType(constants.actions.FATAL_ERROR)) {
        return {
            severity: 'FATAL',
            error: action.error
        }
    }
    return state;
};

export default errorBoundary;
