import React, {useEffect, useState} from 'react';
import AttrList from "../AttrList";
import AddButton from "../controls/AddButton";
import EnumerableInput from "../controls/EnumerableInput";
import Attribute from "../../../../model/Attribute";
import {dataTypes} from '../../../../model/attributeTypes';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import useCurrentlyEditing from "../hooks/useCurrentlyEditing";

const DefaultEditor = ({attribute, org, addNewAttribute, promoteAttribute, removeAttribute, demoteAttribute, deleteRepeatedAttribute, create}) => {

    const [currentlyEditing, setCurrentlyEditing, onChange] = useCurrentlyEditing(org, attribute);

    const handleNewAttribute = () => {
        if (currentlyEditing) {
            addNewAttribute(currentlyEditing);
        }
    };

    const header = attribute.display();
    const value = currentlyEditing.values;
    const addButtonText = global.USER.sourceId !== currentlyEditing.source_id ? 'Add' : 'Update';
    return (
        <>
            {attribute.type.dataType === dataTypes.STRING &&
            <TextField
                key={attribute.name}
                value={value}
                placeholder={`Add new ${header}`}
                style={{width: '80%'}}
                onChange={onChange}
            />
            }
            {attribute.type.dataType === dataTypes.ENUM &&
            <EnumerableInput
                key={attribute.name}
                onChange={onChange}
                attribute={attribute}
            />
            }
            {attribute.type.dataType === dataTypes.BLOB &&
            <TextareaAutosize
                key={attribute.name}
                value={value}
                placeholder={`Add new ${header}`}
                style={{width: '100%', height: '160px'}}
                onChange={onChange}
            />
            }
            <AddButton handleNewAttribute={handleNewAttribute} text={addButtonText} />
            {!create && attribute &&
            <AttrList
                type={attribute.type}
                attributes={attribute}
                promoteAttribute={promoteAttribute}
                addNewAttribute={addNewAttribute}
                removeAttribute={removeAttribute}
                demoteAttribute={demoteAttribute}
                deleteRepeatedAttribute={deleteRepeatedAttribute}
                org={org}
                setCurrentlyEditing={setCurrentlyEditing}
            />
            }
        </>
    );
};

export default DefaultEditor;
