import _ from "lodash";

Array.prototype.swap = function (idx1, idx2) {
    const temp = this[idx1];
    this[idx1] = this[idx2];
    this[idx2] = temp;
}

Array.prototype.promoteIdx = function (idx) {
    const newLeader = this.splice(idx, 1)[0];
    this.unshift(newLeader);
}

Array.prototype.promoteObject = function (obj) {
    this.promoteIdx(this.findIndex((e) => _.isEqual(obj, e)));
}

Array.prototype.promote = function (element) {
    if (typeof element === "object") {
        return this.promoteObject(element);
    } else if (typeof element === "number") {
        return this.promoteIdx(element);
    }
    return -1;
}

Array.prototype.remove = function (element) {
    _.remove(this, (e) => {
        return _.isEqual(e, element);
    });
}

Array.prototype.removeByField = function (fieldName, value) {
    _.remove(this, (e) => {
        return e[fieldName] === value;
    });
}

Array.prototype.toGenericAttributesObj = function (field) {
    return _.reduce(this, (memo, current) => {
        let fieldName = current[field];
        if(Array.isArray(memo[fieldName])){
            memo[current[field]].push(current);
        }
        memo[current[field]] = [current];
        return memo;
    }, {});
}