import React from "react";
import {Typography} from "@material-ui/core";

const Winner = () => {
    return (
        <div>
            <Typography variant="h2">
                You win!!!!
            </Typography>
            <iframe src="https://giphy.com/embed/Ue0jev5l4BKgM" width="480" height="480" frameBorder="0"
                    className="giphy-embed" allowFullScreen />
            <p><a href="https://giphy.com/gifs/girl-blue-super-Ue0jev5l4BKgM">via GIPHY</a></p>
            <Typography>
                Congratulations! You have reached the end of your worklist. Play again.
            </Typography>
        </div>
    )
};

export default Winner;
