import React, {useEffect, useState} from "react";
import TaskTable from "./TaskTable";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import GroupSelector from "./GroupSelector";
import {Button} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import {Toolbar} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: 'fit-content',
    },
    title: {
        flexGrow: 1,
    },
    formGroup: {
      display: 'block',
      flexGrow: 1,
    },
    groupSelector: {
        marginRight: 0,
    },
    taskButton: {
        position: 'relative',
        marginLeft: 0,
        right: 0,
    },
    toolBar: {

        flexGrow: 1,
    }
}));

const WorkList = ({ tasks,
                      fetchOrg,
                      setActivePage,
                      fetchTasks,
                      setActiveTab,
                      activeIndex,
                      currentGroup,
                      setCurrentGroup,
                      activePages,
                      assignTask,
                      assignTaskBulk }) => {

    const tabs = ['to_do', 'in_progress', 'punted', 'done'];
    const {groupList, userList, orgs} = tasks;
    const [currentTab, setCurrentTab] = useState(tabs[activeIndex]);
    const [currentList, setCurrentList] = useState("groupList");

    let history = useHistory();

    const handleSetCurrentList = () => {
      if(currentList === "userList"){
          setCurrentList("groupList");
      }else{
          setCurrentList("userList");
      }
    };

    const onTabChange = (e, o) => {
        setActiveTab(o);
        setCurrentTab(tabs[o]);
    };

    const onGroupSelect = (group) => {
        setCurrentGroup(group);
    };

    useEffect(() => {
        fetchTasks(currentTab, currentGroup);
    }, [currentGroup, currentList]);

    const onStartCuration = () => {
        const {id, org_id} = userList[0];
        history.push(`/task/${id}/edit/${org_id}`);
    };

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Toolbar className={classes.toolBar}>
                <GroupSelector onSelect={onGroupSelect} current={currentGroup} className={classes.groupSelector} />
                <FormGroup className={classes.formGroup}>
                    <FormControlLabel
                        control={
                            <Switch
                                color='primary'
                                checked={currentList === "userList"}
                                value={currentList}
                                onChange={handleSetCurrentList}
                            />
                        }
                        label='My Tasks Only'
                    />
                </FormGroup>
                <div className={classes.taskButton}>
                    <Button
                        disabled={_.filter(userList, t => t.status === currentTab).length < 1}
                        color='secondary'
                        variant='contained'
                        onClick={onStartCuration}
                    >
                        Start Tasks ({_.filter(userList, t => t.status === currentTab).length})
                    </Button>
                </div>
            </Toolbar>
            <Tabs
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                value={activeIndex}
                onChange={onTabChange.bind(this)}
            >
                <Tab label="To do" />
                <Tab label="In Progress" />
                <Tab label="Skipped" />
                <Tab label="Done" />
            </Tabs>
            <TaskTable
                status={currentTab}
                group={currentGroup}
                tasks={{list: tasks[currentList], orgs}}
                fetchOrg={fetchOrg}
                setActivePage={setActivePage}
                activePages={activePages}
                assignTask={assignTask}
                assignTaskBulk={assignTaskBulk}
            />
        </div>
    );
};

export default WorkList;
